import axios from "axios";
export class VehicleService {
  service = "alert";


  getVehicleListByCustomerId(customerId) {
    const url = `${process.env.REACT_APP_BASE_URL}/${this.service}/vehicles`;
    return axios.get(url, {params: {customerId } });
  }

  updateVehicleNotification(payload) {    
    const url = `${process.env.REACT_APP_BASE_URL}/${this.service}/vehicles`;
    return axios.post(url, payload);
  }

  RemoveVehicle(payload) {    
    const url = `${process.env.REACT_APP_BASE_URL}/${this.service}/deletevehicles`;
    return axios.post(url, payload);
  }
}
