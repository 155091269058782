import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ButtonLink } from "@naf/button-link";
import { Grid, GridRow, GridCol } from "@naf/grid";
import { Input, Label } from "@naf/input";
import { CardGrid } from '@naf/card-grid';
import { CardList } from '@naf/cards'
import { Button } from "@naf/button";
import { Text } from "@naf/text";
import { CaseService } from "../../services/CaseService";
import { Dialog } from "@naf/dialog";
import { useToast, ToastVariant } from "@naf/toast";
import { VehicleService } from "../../services/VehicleService";
import { DateUtil } from "../../utils/DateUtils";
import { Checkbox } from "@naf/checkbox";

const Style = styled.div`
.card {
    box-shadow: 8px 8px 24px 8px rgba(0,0,0,0.2);
  }
  .radio-gap {
    display: flex;
    width: 100%;
    gap: 20px;
  }  
  .margins-top
  {
    margin-top:3px
  }
  .margins-top-left
  {
    margin-top:3px;
    margin-left:15px
  }
  .grid-gap-14 {
    grid-gap: 14%;
  }
`;


export default function Vehicle(props) {

    const [loading, setLoading] = useState(false);

    const [order, setOrder] = useState("");
    const [openMemberBook, setOpenMemberBook] = useState(false);
    const [openMembershipCard, setOpenMembershipCard] = useState(false);
    const [openMemberMagazine, setOpenMemberMagazine] = useState(false);
    const [openChargingChip, setOpenChargingChip] = useState(false);
    const [openRemoveVehicle, setOpenRemoveVehicle] = useState(false);

    const [memMegazineDesc, setMemMegazineDesc] = useState("");


    const [vehicleDialogOpen, setVehicleDialogOpen] = useState(false);   

    const [orderDialogOpen, setOrderDialogOpen] = useState(false);

    const [createVehicleDialogOpen, setCreateVehicleDialogOpen] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const [selectedVehicleId, setSelectedVehicleId] = useState(null);
    const [licensePlateNumber, setLicensePlateNumber] = useState(null);
    const [cases, setCases] = useState([]);
    const [loadingVehicles, setLoadingVehicles] = useState(false);
    const [loadingCases, setLoadingCases] = useState(false);

    const { showToast, Toast } = useToast();
  
    const [fieldValueEPostCheckBoxCreate, setFieldValueEPostCheckBoxCreate] = useState(false);
    const [fieldValueSmsCheckBoxCreate, setFieldValueSmsCheckBoxCreate] = useState(false);
     
    const  handleCheckboxChangeEpost =  (checkboxValue,vehicleId) => {   
           upsertVehicle(checkboxValue,vehicleId);
      };
     
    const handleCheckboxChangeSms = (checkboxValue,vehicleId) => {  
        upsertVehicle(checkboxValue,vehicleId);
      };      


    const getVehicles = () => {
        setLoadingVehicles(true)
        const vehicleService = new VehicleService();
        vehicleService.getVehicleListByCustomerId(props?.customer?.customerId).then((res => {
            setLoadingVehicles(false)  
                setVehicles(res?.result)  
        }))
    }

    const getCases = () => {
        const caseService = new CaseService();
        setLoadingCases(true)
        caseService.getCaseByCustomerNumber(props?.customer?.customerNumber).then((res => {
            setLoadingCases(false)
            if (res?.success) {
                const cases = res.result?.cases;
                if (cases.length) {
                    cases.forEach(caseInfo => {
                        if (caseInfo.status === "open") {
                            caseInfo.status = "Under arbeid"
                        }
                        if (caseInfo.status === "closed") {
                            caseInfo.status = "Løst"
                        }
                        if (caseInfo.status === "closeOnCreation") {
                            caseInfo.status = "Lukket"
                        }
                        if (caseInfo.status === "waitingForDetails") {
                            caseInfo.status = "Venter på detaljer"
                        }
                    })
                    setCases(cases)
                }

            }
        }))
    }

    useEffect(() => {
        if (props?.customer?.customerId) {
            getVehicles();
           // getCases();

        } else {
            setVehicles([])
            setCases([])
        }


    }, [props?.customer?.customerId])


    const upsertVehicle = (checkboxValue,vehicleId) => {      
        setLoading(true)
        const vehicleService = new VehicleService();

        const payload = {
            CustomerId: props.customer.customerId,
            VehicleId: vehicleId, 
            NotificationTypes: [
                {
                    Id: 100,
                    Channels: [
                        {
                            Id: 1,
                            Active:checkboxValue?.epost
                        },
                        {
                            Id: 2,
                            Active: checkboxValue?.sms
                        }
                    ]
                }
            ]
        };      
        vehicleService.updateVehicleNotification(payload).then(res => {               
          refresh()
          setLoading(false)
        }).catch(data => {            
            setLoading(false)
        })
    }

    const addVehicle = () => {
        setLoading(true)       
        const vehicleService = new VehicleService();       

        const payload = {
            CustomerId: props.customer.customerId,
            VehicleId: licensePlateNumber,
            NotificationTypes: [
                {
                    Id: 100,
                    Channels: [
                        {
                            Id: 1,
                            Active: fieldValueEPostCheckBoxCreate
                        },
                        {
                            Id: 2,
                            Active: fieldValueSmsCheckBoxCreate
                        }
                    ]
                }
            ]
        };    
        vehicleService.updateVehicleNotification(payload).then(res => {           
            setLoading(false)
            setVehicleDialogOpen(false)
            setFieldValueEPostCheckBoxCreate(false)
            setFieldValueSmsCheckBoxCreate(false)           
            refresh();

        }).catch(data => {
            setLicensePlateNumber("")
            setFieldValueEPostCheckBoxCreate(false)
            setFieldValueSmsCheckBoxCreate(false)
            setLoading(false)
        })
    }

    const removeVehicle = () => {
        setLoading(true)
        const vehicleService = new VehicleService();       
        const payload = {           
            customerId: props.customer.customerId,
            customerNumber: props.customer.customerNumber,
            vehicleId: selectedVehicleId       
        }
        vehicleService.RemoveVehicle(payload).then(data => {
            setLoading(false)
            setOpenRemoveVehicle(false)           
            refresh();
        }).catch(data => {
            setLoading(false)
        })
    }

    const orderMemberBook = () => {
        setLoading(true)
        const caseService = new CaseService();
        const payload = {
            category: 'LOGLVL10_1',
            mainReason: 'LOGLVL20_8',
            subReason: 'LOGLVL30_40',
            customerId: props.customer.customerId,
            customerNumber: props.customer.customerNumber,
            description: "Bestille veibok",
            status: 'closeOnCreation',
            title: 'Bestille veibok',

        }
        caseService.createCase(payload).then(data => {
            setLoading(false)
            setOpenMemberBook(false)
            setOrderDialogOpen(true)
        }).catch(data => {
            setLoading(false)
        })
    }

    const orderMemberMegazine = () => {
        setLoading(true)
        const caseService = new CaseService();
        const payload = {
            category: 'LOGLVL10_1',
            mainReason: 'LOGLVL20_8',
            subReason: 'LOGLVL30_123',
            customerId: props.customer.customerId,
            customerNumber: props.customer.customerNumber,
            description: `Utgaver: ${memMegazineDesc}`,
            status: 'open',
            title: 'Bestille Motor'
        }
        caseService.createCase(payload).then(data => {
            setLoading(false)
            setOpenMemberMagazine(false)
            setOrderDialogOpen(true)
            setMemMegazineDesc("")

        }).catch(data => {
            setLoading(false)
        })
    }

    const orderChargingChip = () => {
        setLoading(true)
        const caseService = new CaseService();
        const payload = {
            category: 'LOGLVL10_1',
            mainReason: 'LOGLVL20_8',
            subReason: 'LOGLVL30_37',
            customerId: props.customer.customerId,
            customerNumber: props.customer.customerNumber,
            status: 'closeOnCreation',
            description: "Bestille laderikke",
            title: 'Bestille ladebrikke'
        }
        caseService.createCase(payload).then(data => {
            setLoading(false)
            setOpenChargingChip(false)
            setOrderDialogOpen(true)

        }).catch(data => {
            setLoading(false)
        })
    }

    const orderMemberCard = () => {
        setLoading(true)
        const caseService = new CaseService();
        const payload = {
            category: 'LOGLVL10_7',
            mainReason: 'LOGLVL20_31',
            subReason: 'LOGLVL30_124',
            customerId: props.customer.customerId,
            customerNumber: props.customer.customerNumber,
            status: 'closeOnCreation',
            description: "Bestille medlemskort",
            title: 'Bestille medlemskort'
        }
        caseService.createCase(payload).then(data => {
            setLoading(false)
            setOrderDialogOpen(true)
            setOpenMembershipCard(false)

        }).catch(data => {
            setLoading(false)
        })
    }
    const processOrder = () => {
        if (order === "Order Member Book") {
            orderMemberBook()
        } else if (order === "Order Membereship Card") {
            orderMemberCard()
        } else if (order === "Order Member Magazine") {
            orderMemberMegazine()
        } else if (order === "Order Charging Chip") {
            orderChargingChip()
        } else if (order === "Remove Vehicle") {
            removeVehicle()
        }
    }

    const refresh = () => {
        getVehicles()
       // getCases()
    }

    return (
        <Style>
            <Toast></Toast>

            <div style={{ borderBottom: "1px solid #0000001f" }}>
                <Grid>
                <GridRow>
                <GridCol l="10" m="10" s="10" xl="10">
                </GridCol>
                <GridCol l="2" m="2" s="2" xl="2">
                <div className="d-flex m-1 justify-content-end">                     
                        <Button size="small" isLoading={loadingCases || loadingVehicles} onClick={() => {refresh() }}>Oppdater</Button>
                </div>
                </GridCol>
                </GridRow>
                    <div className="d-flex align-items-center">
                        <Text
                            variant="header3"
                        >
                            Varslinger
                        </Text>
                        <Button style={{ marginLeft: "2em" }} onClick={() => { setLicensePlateNumber(""); setVehicleDialogOpen(true) }}>Legg til kjøretøy</Button>
                    </div>

                    <GridRow className="grid-gap-14">
                        {
                            vehicles?.map(vehicle => {
                                const nameNorwegianValue = vehicle?.notificationTypes?.some(notificationType => notificationType?.id === 100) ? 
                                vehicle?.notificationTypes?.find(type => type?.id === 100)?.nameNorwegian : null;
                        
                               // Extract the channel with ID 1 for E-Post
                                const notificationEPostChannel = vehicle?.notificationTypes?.some(notificationType => notificationType?.id === 100) ? 
                                vehicle.notificationTypes.find(notificationType => notificationType?.id === 100)?.channels?.find(channel => channel?.id === 1) : null;
                              
                                  // Extract the channel with ID 2 for Sms
                                const notificationSmsChannel = vehicle?.notificationTypes?.some(notificationType => notificationType?.id === 100) ? 
                                vehicle?.notificationTypes?.find(notificationType => notificationType?.id === 100)?.channels?.find(channel => channel?.id === 2) : null;
            
                                return <>
                                    <GridCol s="12" m="12" l="4" xl="4">
                                        <CardList
                                            label={{
                                                text: vehicle?.vehicle?.licensePlateNumber,
                                                variant: 'signature'
                                            }}
                                            style={{ marginBottom: "0px", borderBottom: "0px" }}
                                            title={vehicle?.vehicle?.basic?.manufactureName + " " + vehicle?.vehicle?.basic?.modelName + " " + vehicle?.vehicle?.basic?.year}
                                            variant="standard"
                                        >
                                            <div>  
                                             <Text
                                                style={{
                                                    margin: 0
                                                }}
                                                tag="p"
                                                variant="bodyBold"
                                                >
                                                {nameNorwegianValue}
                                                </Text>
                                            </div>
                                            <div className="d-flex col-gap justify-content-between">
                                            Sist kontroll {DateUtil.convertToDisplayFormat(vehicle?.vehicle?.registrationAndInspection?.lastInspectionDate)+ ",       "}    
                                            Neste kontroll {DateUtil.convertToDisplayFormat(vehicle?.vehicle?.registrationAndInspection?.nextInspectionDate)} 
                                            </div>
                                            {Boolean(nameNorwegianValue) && ( 
                                                        <div className="radio-gap d-flex col-gap">
                                                            <Checkbox                                                           
                                                             onChange={() =>handleCheckboxChangeEpost({epost:!notificationEPostChannel?.active,sms:notificationSmsChannel?.active}, vehicle?.vehicleId)}
                                                            label="E-post"
                                                            name="ePost"                                                          
                                                             checked={notificationEPostChannel?.active}
                                                            />
                                                            {<span className="margins-top">{DateUtil.convertToDisplayFormat(notificationEPostChannel?.updated || notificationEPostChannel?.created)+ ", "} {notificationEPostChannel?.updatedByName || notificationEPostChannel?.createdByName}</span>}
                                                            </div>
                                                            ) }
                                                            {Boolean(nameNorwegianValue) && ( 
                                                            <div className="radio-gap d-flex">
                                                                 <Checkbox                                                                                                                    
                                                             onChange={() =>
                                                                 handleCheckboxChangeSms({epost:notificationEPostChannel?.active,sms:!notificationSmsChannel?.active}, vehicle?.vehicleId)}
                                                            label="SMS"
                                                            name="sms"
                                                             checked={notificationSmsChannel?.active}
                                                            />                                                           
                                                            {<span className="margins-top-left">{DateUtil.convertToDisplayFormat(notificationSmsChannel?.updated || notificationSmsChannel?.created)+ ", "} {notificationSmsChannel?.updatedByName || notificationSmsChannel?.createdByName}</span>}                                               
                                                        </div>                                            
                                                           ) }
                                            <Button size="small" onClick={() => { setOpenRemoveVehicle(true); setSelectedVehicleId(vehicle?.vehicleId) }} variant="outline" style={{ marginTop: "1em" }} >Fjern kjøretøy</Button>
                                        </CardList>
                                    </GridCol>
                                </>
                            })
                        }
                        {
                            !vehicles.length && <div className="m-1 p-1"></div>
                        }
                    </GridRow>
                </Grid>
            </div>
            <div >

                <Grid >
                    <Text

                        variant="header3"
                        style={{ paddingTop: "20px" }}
                    >
                        Tjenester
                    </Text>
                    <div className="d-flex col-gap " style={{ paddingTop: "16px", paddingBottom: "40px" }}>
                        <Button size="small" disabled={!props?.activeMembership?.membership} onClick={() => { setOpenMemberBook(true) }} >Bestill veibok</Button>
                        <Button size="small" disabled={!props?.activeMembership?.membership} onClick={() => { setOpenMembershipCard(true) }}>Bestill medlemskort</Button> 
                        <Button size="small" disabled={!props?.activeMembership?.membership} onClick={() => { setOpenMemberMagazine(true) }}>Bestill Motor</Button>
                        <Button size="small" disabled={!props?.activeMembership?.membership} onClick={() => { setOpenChargingChip(true) }} >Bestill ladebrikke</Button>
                    </div>




                    <Dialog isOpen={openMemberBook} handleClose={() => setOpenMemberBook(false)} button={<>
                        <Button onClick={() => setOpenMemberBook(false)} variant="outline">Avbryt</Button>
                        <Button isLoading={loading} onClick={() => orderMemberBook()}>Bestill veibok</Button>
                    </>}>
                        Ønsker du å bestille veibok til kunden?
                    </Dialog>


                    <Dialog isOpen={openMembershipCard} handleClose={() => setOpenMembershipCard(false)} button={<>
                        <Button  onClick={() => setOpenMembershipCard(false)} variant="outline">Avbryt</Button>
                        <Button isLoading={loading}  onClick={() => orderMemberCard()} >Bestill medlemskort</Button>
                    </>}>
                        Ønsker du å bestille medlemskort til kunden?
                    </Dialog>


                    <Dialog isOpen={openMemberMagazine} handleClose={() => setOpenMemberMagazine(false)} button={<>
                        <Button onClick={() => setOpenMemberMagazine(false)} variant="outline">Avbryt</Button>
                        <Button isLoading={loading}  disabled={!memMegazineDesc} onClick={() => orderMemberMegazine()}>Bestill Motor</Button>
                    </>}>
                        Ønsker du å bestille Motor utgave til kunden?
                        <Label >Oppgi utgave(r) som skal bestilles </Label>
                        <Input onChange={val => setMemMegazineDesc(val)} value={memMegazineDesc} placeholder="Utgaver 1,2,3,4" as="textarea" />
                    </Dialog>


                    <Dialog isOpen={openChargingChip} handleClose={() => setOpenChargingChip(false)} button={<>
                        <Button onClick={() => setOpenChargingChip(false)} variant="outline">Avbryt</Button>
                        <Button isLoading={loading}  onClick={() => orderChargingChip()}>Bestill ladebrikke</Button>
                    </>}>
                        Ønsker du å bestille ladebrikke til kunden?
                    </Dialog>

                    <Dialog isOpen={openRemoveVehicle} handleClose={() => setOpenRemoveVehicle(false)} button={<>
                        <Button onClick={() => setOpenRemoveVehicle(false)} variant="outline">Avbryt</Button>
                        <Button isLoading={loading} onClick={() => removeVehicle()}>Fjern varsel</Button>
                    </>}>
                        Ønsker kunden å fjerne varsel for kjøretøyet?
                    </Dialog>

                    <Dialog isOpen={vehicleDialogOpen} title="Opprett varsel" handleClose={() => setVehicleDialogOpen(false)} button={<>
                        <Button onClick={() => setVehicleDialogOpen(false)} variant="outline">Avbryt</Button>
                        <Button isLoading={loading}  disabled={!licensePlateNumber} onClick={addVehicle}>Opprett varsel</Button>
                    </>}>

                        <Label>Reg. nummer</Label>
                        <Input
                            onChange={(val) => setLicensePlateNumber(val)}
                            value={licensePlateNumber}
                            name="licensePlateNumber"
                        />

                                                        <div className="radio-gap d-flex col-gap justify-content-between" style={{ marginTop: "10px"}}>
                                                            <Checkbox
                                                             onChange={(value) =>
                                                                setFieldValueEPostCheckBoxCreate(                                                                
                                                                  (!eval(false))
                                                                )
                                                              }                                                          
                                                            label="E-post"
                                                            name="ePost.notification"
                                                            checked={false}
                                                            />
                                                             </div>
                                                            <div className="d-flex justify-content-between">
                                                            <Checkbox
                                                            onChange={(value) =>
                                                                setFieldValueSmsCheckBoxCreate(                                                                
                                                                  (!eval(false))
                                                                )
                                                              }   
                                                            label="SMS"
                                                            name="sms.notification"
                                                            checked={false}
                                                            />     
                                                            </div> 


                    </Dialog>

                    <Dialog isOpen={createVehicleDialogOpen} handleClose={() => setCreateVehicleDialogOpen(false)} button={<>
                        <Button  onClick={() => setCreateVehicleDialogOpen(false)} variant="outline" style={{ float: "right" }}>Ok</Button>
                    </>}>
                        Melding om å opprette varsel for {licensePlateNumber} er sendt til kundesenteret. Det kan ta noen dager før endringen er utført
                    </Dialog>

                    <Dialog isOpen={orderDialogOpen} handleClose={() => setOrderDialogOpen(false)} button={<>
                        <Button  onClick={() => setOrderDialogOpen(false)} variant="outline" style={{ float: "right" }}>Ok</Button>
                    </>}>

                        Bestilling er sendt inn.
                        Det kan ta opptil 2 uker før kunden mottar det bestilte produktet
                    </Dialog>

                </Grid>
            </div>

            <Grid>
                <Text
                    style={{
                        paddingTop: "30px",
                        borderTop: "1px solid rgba(0, 0, 0, 0.12)"
                    }}
                    variant="header3"
                >
                    Henvendelser
                </Text>
                <GridRow columns="12">

                    {cases?.map(caseInfo => {
                        return <GridCol key={caseInfo?.ticketNumber} s="6" m="6" l="6" xl="6">
                            <div className="border p-1 d-flex flex-direction-column " >
                                <div className="d-flex col-gap justify-content-between">
                                    <div>
                                        <Text style={{
                                            margin: "0px",
                                        }}
                                            variant="bodyBold"
                                        >
                                            {caseInfo?.title}

                                        </Text>
                                    </div>
                                    <div>

                                        <Text
                                            variant="details"
                                            style={{
                                                margin: "0px",
                                            }}
                                        >
                                            {DateUtil.convertToDisplayFormat(
                                                caseInfo?.createdDate
                                            )}
                                        </Text>
                                    </div>
                                </div>

                                <div className="d-flex  col-gap align-items-center justify-content-between">

                                    <div>
                                        <Text
                                            variant="details"
                                            style={{
                                                margin: "0px",
                                            }}
                                        >
                                            {caseInfo?.description}
                                        </Text>
                                    </div>
                                    <div className="d-flex">
                                        <Text
                                            variant="details"
                                            style={{
                                                margin: "0px",
                                                flex: "none"

                                            }}
                                        >

                                        </Text>
                                        <Text
                                            variant="details"
                                            style={{
                                                margin: "0px",
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap"
                                            }}
                                        >
                                            {caseInfo?.status}
                                        </Text>
                                    </div>

                                </div>
                            </div>
                        </GridCol>
                    })}
                </GridRow>
            </Grid>

        </Style>
    );
}
