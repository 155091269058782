import React, { useState, useEffect } from "react";
import with_roadside_assistance from "../assets/images/with_roadside_assistance.png";
import without_roadside_assistance from "../assets/images/without_roadside_assistance.png";
import check from "../assets/images/check.svg";
import cross from "../assets/images/cross.svg";
import { GridRow, GridCol } from "@naf/grid";
import { Text } from "@naf/text";
import styled from "styled-components";
import { ProductService } from "../services/ProductService";
import { Button } from "@naf/button";
import {
  ProductInfo,
  ProductWithRoadsideAssistanceDetails,
  ProductWithOutRoadsideAssistanceDetails,
} from "../constant/product.constant";

const Style = styled.div`
  .product {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 54px;
    padding-left: 64px;
    padding-bottom: 64px;
  }
`;

export default function Product(props) {
  const [step, setStep] = [props.step, props.setStep];
  const { customer, setInvoice } = props;
  // const { user } = useAuth0();
  const productService = new ProductService();
  const [loadingProductWithRoadSide, setLoadingProductWithRoadSide] =
    useState(false);
  const [loadingProductWithoutRoadSide, setLoadingProductWithoutRoadSide] =
    useState(false);

  const confirmOrder = (productType) => {
    const payload = {
      CustomerId: customer.customerId,
      CustomerNumber: customer.customerNumber,
      SalesOrder: {
        Status: "submitted",
        // SalesAgent: user.email,
        SalesLocationNumber: "90407446",
        SalesChannel: "web",
        PaymentInterval: "yearly",
        SalesOrderLines: [],
      },
    };
    if (productType === ProductInfo.product_with_roadside_assistance) {
      setLoadingProductWithRoadSide(true);
      payload.SalesOrder.SalesOrderLines = [
        {
          ...ProductWithRoadsideAssistanceDetails,
        },
        {
          ...ProductWithOutRoadsideAssistanceDetails,
        },
      ];
    } else if (
      productType === ProductInfo.product_with_out_roadside_assistance
    ) {
      setLoadingProductWithoutRoadSide(true);
      payload.SalesOrder.SalesOrderLines = [
        {
          ...ProductWithOutRoadsideAssistanceDetails,
        },
      ];
    }
    if (payload?.SalesOrder?.SalesOrderLines?.length) {
      payload.SalesOrder.SalesOrderLines.forEach((line) => {
        line.ProductUserCustomerId = customer.customerId;
      });
    }
    productService.createSalesOrder(payload).then((data) => {
      setLoadingProductWithRoadSide(false);
      setLoadingProductWithoutRoadSide(false);
      if (data.success) {
        if (data.result?.invoice) {
          setInvoice({ ...data.result.invoice, productType });
          setStep(3);
        } else if (data.hasUnpaidInvoice) {
          setInvoice({ hasUnpaidInvoice: true, productType });
          setStep(3);
        }
      } 
    });
  };
  return (
    <Style>
      <div>
        <Text
          tag="h2"
          style={{
            marginBottom: "32px",
          }}
          variant="header2"
        >
          Velg medlemskap
        </Text>
        <GridRow columns="12">
          <GridCol m="6" l="6" xl="6" s="6">
            <div className="product border">
              <div className="d-flex w-100 col-gap align-items-center">
                <Text
                  tag="h3"
                  style={{
                    margin: 0,
                  }}
                  variant="header3"
                >
                  Medlemskap med veihjelp
                </Text>
              </div>

              <div className="icon">
                <img alt="road-side-assitance" src={with_roadside_assistance} />
              </div>
              <div className="plan-detail-container">
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Veihjelp i Norge og Europa
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Bilnøkkelforsikring
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Juridisk rådgivning
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Egenandelsforsikring for leiebil
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Veibok og Motor
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Rabatt på EU-kontroll, tester og service
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Gunstig billån og forsikring
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Ladebrikke og ladefordeler
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Omfattende fordelsprogram
                  </Text>
                </div>
              </div>
              <div className="d-flex   flex-direction-column">
                <Text style={{ margin: "0px" }} tag="h3" variant="header3">
                  1 320 kr
                </Text>
                <Text style={{ margin: "0px" }} tag="p" variant="body">
                  for 12 måneder
                </Text>
              </div>
              <Button              
                size="small"               
                style={{ marginTop: "40px" }}
                isLoading={loadingProductWithRoadSide}
                disabled={loadingProductWithoutRoadSide}
                onClick={() =>
                  confirmOrder(ProductInfo.product_with_roadside_assistance)
                }              
              >Velg</Button>
            </div>
          </GridCol>
          <GridCol m="6" l="6" xl="6" s="6">
            <div className="product border">
              <div className="d-flex w-100 col-gap align-items-center">
                <Text                  
                  style={{
                    margin: 0
                  }}
                  tag="p"
                  variant="header3"
                >
                  Medlemskap uten veihjelp
                </Text>
              </div>

              <div className="icon">
                <img
                  alt="road-side-assitance"
                  src={without_roadside_assistance}
                />
              </div>
              <div className="plan-detail-container">
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={cross} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Veihjelp i Norge og Europa
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={cross} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Bilnøkkelforsikring
                  </Text>
                </div>

                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Juridisk rådgivning
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={cross} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Egenandelsforsikring for leiebil
                  </Text>
                </div>

                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Veibok og Motor
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Rabatt på EU-kontroll, tester og service
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Gunstig billån og forsikring
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Ladebrikke og ladefordeler
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Omfattende fordelsprogram
                  </Text>
                </div>
              </div>
              <div className="d-flex   flex-direction-column">
                <Text style={{ margin: "0px" }} tag="h3" variant="header3">
                  630 kr
                </Text>
                <Text style={{ margin: "0px" }} tag="p" variant="body">
                  for 12 måneder
                </Text>
              </div>
              <Button    
                size="small"
                style={{ marginTop: "40px" }}
                isLoading={loadingProductWithoutRoadSide}
                disabled={loadingProductWithRoadSide}
                onClick={() =>
                  confirmOrder(ProductInfo.product_with_out_roadside_assistance)
                }                
              >Velg</Button>
            </div>
          </GridCol>
        </GridRow>
      </div>
    </Style>
  );
}
