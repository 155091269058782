import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

export default function AuthRoute(props) {
  const  isAuthenticated = useIsAuthenticated()
  console.log(isAuthenticated);
  return (
    <Route
      path={props.path}
      render={(data) =>
        !isAuthenticated ? (
          <props.component {...data}></props.component>
        ) : (
          <Redirect to={{ pathname: "/register" }}></Redirect>
        )
      }
    ></Route>
  );
}
