import axios from "axios";
export class NewsLetterService {
  service = "newsletter";


  getNewsLetterByCustomerId(customerId) {
    const url = `${process.env.REACT_APP_BASE_URL}/${this.service}`;
    return axios.get(url, {params: {customerId} });

  }
}
