import axios from "axios";
export class InvoiceService {
  service = "invoice";

  markInvoiceAsPaid(invoice) {
    const url = `${process.env.REACT_APP_BASE_URL}/${this.service}/payinvoice`;

    return axios.post(url, invoice);
  }
}
