import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Grid, GridRow, GridCol } from "@naf/grid";
import {
  Input,
  Label,
  Message,
  DateField,
  DateWrapper,
  Month,
  Day,
  Year,
  Phone,
} from "@naf/input";
import { RadioButton } from "@naf/radio-button";
import * as Yup from "yup";
import { useFormik } from "formik";
import styled from "styled-components";
import { Text } from "@naf/text";
import { Toggle } from "@naf/toggle";
import { Checkbox } from "@naf/checkbox";
import { CommonUtil } from "../utils/CommonUtils";
import { MasterService } from "../services/MasterService";

const Style = styled.div`
  padding-top: 80px;
  .all-terms-container {
    display: flex;
    width: 100%;
    gap: 55px;
    margin-top: 23px;
  }
  .radio-gap {
    display: flex;
    width: 100%;
    gap: 20px;
  }
`;
const Customer = forwardRef((props, ref) => {
  const [phoneNumberVisible, setphoneNumberVisible] = useState(true);
  const [showConsents, setShowConsents] = useState(true);
  const [showAllConsentOptions, setShowAllConsentOptions] = useState(true);

  const formik = useFormik({
    validateOnMount: true,
    initialValues: props.initialValueOfCustomer
      ? props.initialValueOfCustomer
      : {
        hasActiveMembership: false,
        customerNumber: "",
        customerId: "",
        agreeAllConsent: false,
        basic: {
          firstName: "",
          lastName: "",
          email: "",
          day: "",
          month: "",
          year: "",
          gender: "male",
          mobilePhone: props.mobilePhone,
        },
        ePost: {
          consentNumber: "30",
          consented: "false",
        },
        sms: {
          consentNumber: "40",
          consented: "false",
        },
        ePostNew: {
          consentNumber: "120",
          consented: "false",
        },
        smsNew: {
          consentNumber: "110",
          consented: "false",
        },
        useInformationConsent: {
          consentNumber: "10",
          consented: "false",
        },
        shareInformationConsent: {
          consentNumber: "20",
          consented: "false",
        },
        address: {
          streetLine1: "",
          streetLine2: "",
          postalCode: "",
          city: "",
          country: "",
        },
      },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      basic: Yup.object().shape({
        mobilePhone: Yup.string()
          .required("Vennligst fyll ut Mobilnummer")
          .nullable()
          .test({
            test: (value) => {
              return !isNaN(parseFloat(value)) && value?.length === 8;
            },
            message: "Dette er ikke en gyldig mobilnummer",
          }),
        firstName: Yup.string()
          .nullable()
          .required("Vennligst fyll ut Fornavn"),
        lastName: Yup.string()
          .nullable()
          .required("Vennligst fyll ut Etternavn"),
        email: Yup.string()
          .nullable()
          .email("Dette er ikke en gyldig e-post")
        ,
        day: Yup.string()
          .nullable()
        ,
        month: Yup.string()
          .nullable()
        ,
        year: Yup.string()
          .nullable()
        ,
        gender: Yup.string().nullable(),
      }),
      address: Yup.object().shape({
        streetLine1: Yup.string()
          .nullable()
          .required("Vennligst fyll ut Addresse"),
        postalCode: Yup.string()
          .nullable()
          .required("Vennligst fyll ut Postnummer")
          .length(4, "Dette er ikke et gyldig postnummer"),
        city: Yup.string().nullable().required("Vennligst fyll ut Poststed"),
      }),
    }),
  });
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    setTouched,
    setFieldValue,
  } = formik;
  const { initialValueOfCustomer } = props;
  const countries = CommonUtil.getCountries();

  useEffect(() => {
    setShowConsents(true);
  }, [showConsents]);

  useEffect(() => {
    if (
      values.shareInformationConsent?.consented === "true" &&
      values.ePost?.consented === "true" &&
      values.sms?.consented === "true" &&
      values.ePostNew?.consented === "true" &&
      values.smsNew?.consented === "true" &&
      values.useInformationConsent?.consented === "true"
    ) {
      setFieldValue("agreeAllConsent", true);
      setShowAllConsentOptions(false);
    } else {
      setFieldValue("agreeAllConsent", false);
      setShowAllConsentOptions(false);
    }
  }, [
    values.shareInformationConsent?.consented,
    values.ePost?.consented,
    values.sms?.consented,
    values.ePostNew?.consented,
    values.smsNew?.consented,
    values.useInformationConsent?.consented,
  ]);


  useEffect(() => {
    setShowAllConsentOptions(true);
    setShowConsents(true);
  }, [showAllConsentOptions, showConsents]);

  useEffect(() => {
    if (values.agreeAllConsent) {
      setFieldValue("shareInformationConsent.consented", "true");
      setFieldValue("ePost.consented", "true");
      setFieldValue("sms.consented", "true");
      setFieldValue("ePostNew.consented", "true");
      setFieldValue("smsNew.consented", "true");
      setFieldValue("useInformationConsent.consented", "true");
      setShowConsents(false);
    }
  }, [values.agreeAllConsent]);

  useEffect(() => {
    resetForm();
    setphoneNumberVisible(false);
  }, [initialValueOfCustomer, resetForm]);

  useEffect(() => {
    setphoneNumberVisible(true);
  }, [phoneNumberVisible]);

  useImperativeHandle(ref, () => ({
    values,
    errors,
    setTouched,
  }));

  useEffect(() => {
    resetForm();
  }, []);


  const getcityByPostCode = (postCode) => {
    setFieldValue("address.city", "");

    const masterService = new MasterService();
    if (postCode?.length === 4) {
      masterService.getcityByPostCode(postCode).then((res) => {
        if (res?.result?.city) {
          setFieldValue("address.city", res?.result?.city);
        }
      })

    }
  }

  if (!values) {
    return <></>;
  }
  return (
    <Style>
      <Text
        tag="h2"
        style={{
          marginTop: "0px",
          marginBottom: "40px",
        }}
        variant="header2"
      >
        Kundeinformasjon
      </Text>

      <form onSubmit={handleSubmit}>
        <Grid>
          <GridRow columns="12">

            <>
              <GridCol m="6" l="6" xl="6">
                <Label>Mobilnummer*</Label>
                {phoneNumberVisible && (
                  <Phone
                    placeholder="8 siffer"
                    pattern="[0-9]+"
                    name="basic.mobilePhone"
                    countries={countries}
                    onChange={(value) =>
                      setFieldValue("basic.mobilePhone", value.number)
                    }
                    onBlur={handleBlur}
                    value={values.basic?.mobilePhone || ""}
                    selectedCountry={countries[3]}

                    error={
                      errors?.basic?.mobilePhone && touched.basic?.mobilePhone
                    }
                  />
                )}

                {errors?.basic?.mobilePhone && touched.basic?.mobilePhone && (
                  <Message error> {errors?.basic?.mobilePhone}</Message>
                )}
              </GridCol>
              <GridCol m="6" l="6" xl="6">
                {values.hasActiveMembership && (
                  <>
                    <Label>Kundenummer</Label>
                    <Input
                      onInput={handleChange}
                      onBlur={handleBlur}
                      value={values.customerNumber || ""}
                      name="customerNumber"
                      disabled={true}
                      error={errors?.customerNumber && touched.customerNumber}
                    />
                    {errors?.customerNumber && touched.customerNumber && (
                      <Message error> {errors?.customerNumber}</Message>
                    )}
                  </>
                )}
              </GridCol>
            </>
            <GridCol m="6" l="6" xl="6">
              <Label>Fornavn og mellomnavn*</Label>
              <Input
                onChange={(value) =>
                  setFieldValue("basic.firstName", value)
                }
                value={values?.basic?.firstName}
                name="basic.firstName"

                error={errors?.basic?.firstName && touched?.basic?.firstName}
              />
              {errors?.basic?.firstName && touched?.basic?.firstName && (
                <Message error> {errors?.basic?.firstName}</Message>
              )}
            </GridCol>
            <GridCol m="6" l="6" xl="6">
              <Label>Etternavn*</Label>
              <Input
                onChange={(value) =>
                  setFieldValue("basic.lastName", value)
                }
                value={values.basic.lastName || ""}
                name="basic.lastName"

                error={errors?.basic?.lastName && touched?.basic?.lastName}
              />
              {errors?.basic?.lastName && touched?.basic?.lastName && (
                <Message error> {errors?.basic?.lastName}</Message>
              )}
            </GridCol>

            <GridCol m="12" l="12" xl="12">
              <Label>E-post</Label>
              <Input
                onChange={(value) =>
                  setFieldValue("basic.email", value)
                }
                value={values.basic?.email || ""}
                name="basic.email"
                width="100%"

                error={errors?.basic?.email && touched?.basic?.email}
              />
              {errors?.basic?.email && touched?.basic?.email && (
                <Message error> {errors?.basic?.email}</Message>
              )}
            </GridCol>

            <GridCol m="12" l="12" xl="12">
              <Label toolTip="Used to give age discount">Fødselsdato</Label>
              <DateWrapper>
                <DateField>
                  <Day
                    id="day"
                    onChange={(value) =>
                      setFieldValue("basic.day", value)
                    }
                    value={values.basic.day || ""}
                    name="basic.day"
                    nextId="month1"

                    error={errors?.basic?.day && touched?.basic?.day}
                  />
                  {errors?.basic?.day && touched?.basic?.day && (
                    <Message error> {errors?.basic?.day}</Message>
                  )}
                </DateField>
                <DateField>
                  <Month
                    id="month1"
                    nextId="year1"
                    onChange={(value) =>
                      setFieldValue("basic.month", value)
                    }
                    value={values.basic.month || ""}
                    name="basic.month"

                    error={errors?.basic?.month && touched?.basic?.month}
                  />
                  {errors?.basic?.month && touched?.basic?.month && (
                    <Message error> {errors?.basic?.month}</Message>
                  )}
                </DateField>
                <DateField>
                  <Year
                    id="year1"
                    placeholder="yyyy"
                    onChange={(value) =>
                      setFieldValue("basic.year", value)
                    }
                    value={values.basic.year || ""}
                    name="basic.year"

                    error={errors?.basic?.year && touched?.basic?.year}
                  />
                  {errors?.basic?.year && touched?.basic?.year && (
                    <Message error> {errors?.basic?.year}</Message>
                  )}
                </DateField>
              </DateWrapper>
            </GridCol>

            <GridCol m="12" l="12" xl="12">
              <Label>Kjønn </Label>
              <div className="d-flex justify-content-between">
                <RadioButton
                  label="Kvinne"
                  value="female"
                  onClick={handleChange}

                  name="basic.gender"
                  checked={values.basic.gender === "female"}
                />
                <RadioButton
                  label="Mann"
                  onClick={handleChange}
                  name="basic.gender"

                  value="male"
                  checked={values.basic.gender === "male"}
                />
                <RadioButton
                  label="Vil ikke oppgi kjønn"
                  onClick={handleChange}
                  name="basic.gender"

                  value="unknown"
                  checked={values.basic.gender === "unknown"}
                />
              </div>
            </GridCol>

            <GridCol m="12" l="12" xl="12">
              <Label>Addresse*</Label>
              <Input
                onChange={(value) =>
                  setFieldValue("address.streetLine1", value)
                }
                value={values.address.streetLine1 || ""}
                width="100%"
                name="address.streetLine1"

                error={
                  errors?.address?.streetLine1 && touched.address?.streetLine1
                }
              />
              {errors?.address?.streetLine1 && touched.address?.streetLine1 && (
                <Message error> {errors?.address?.streetLine1}</Message>
              )}
            </GridCol>

            <GridCol m="6" l="6" xl="6">
              <Label>Postnummer*</Label>
              <Input
                onChange={(value) => {
                  setFieldValue("address.postalCode", value)
                  getcityByPostCode(value)
                }
                }
                value={values.address.postalCode || ""}

                name="address.postalCode"
                error={
                  errors?.address?.postalCode && touched.address?.postalCode
                }
              />
              {errors?.address?.postalCode && touched?.address?.postalCode && (
                <Message error> {errors?.address?.postalCode}</Message>
              )}
            </GridCol>
            <GridCol m="6" l="6" xl="6">
              <Label>Poststed*</Label>
              <Input
                onChange={(value) =>
                  setFieldValue("address.city", value)
                }
                value={values.address.city || ""}
                disabled={true}
                name="address.city"
                error={errors?.address?.city && touched.address?.city}
              />
              {errors?.address?.city && touched.address?.city && (
                <Message error> {errors?.address?.city}</Message>
              )}
            </GridCol>
            <GridCol m="12" l="12" xl="12">
              <Text
                style={{
                  marginTop: "28px",
                  marginBottom: "18px",
                }}
                variant="header3"
              >
                Samtykker
              </Text>

            </GridCol>
            {showAllConsentOptions && (
              <GridCol m="12" l="12" xl="12" className="all-terms-container">
                <div>
                  <Toggle
                    checked={values.agreeAllConsent === true}
                    onClick={handleChange}
                    name="agreeAllConsent"
                  />
                </div>
                <Label
                  variant="body"
                  width={"100%"}
                  className="d-flex align-items-center "
                >
                  Jeg samtykker ‘Ja’ til alle punktene under
                </Label>
              </GridCol>
            )}

            {showConsents && (
              <>
                <GridCol m="12" l="12" xl="12">
                  <Label variant="body" width={"100%"}>
                    NAF kan benytte opplysninger knyttet til mitt medlemskap/kundeforhold for å gi meg tilpassede tilbud og nyttig informasjon

                  </Label>
                  <div className="radio-gap">
                    <RadioButton
                      label="Ja"
                      value="true"
                      onClick={handleChange}
                      disabled={
                        values.agreeAllConsent
                      }
                      checked={
                        values.useInformationConsent.consented === "true"
                      }
                      name="useInformationConsent.consented"
                    />
                    <RadioButton
                      label="Nei"
                      onClick={handleChange}
                      disabled={
                        values.agreeAllConsent
                      }
                      checked={
                        values.useInformationConsent.consented === "false"
                      }
                      name="useInformationConsent.consented"
                      value="false"
                    />
                  </div>
                </GridCol>
                {values.useInformationConsent.consented === "true" && (
                  <GridCol m="12" l="12" xl="12">
                    <Label variant="body" width={"100%"}>
                      Velg kanal:
                    </Label>
                    <div className="radio-gap">
                      <Checkbox
                        onChange={(value) =>
                          setFieldValue(
                            "ePost.consented",
                            (!eval(values?.ePost?.consented)).toString()
                          )
                        }
                        disabled={
                          values.agreeAllConsent
                        }
                        label="E-post"
                        name="ePost.consented"
                        checked={values.ePost.consented === "true"}
                      />
                      <Checkbox
                        onChange={(value) =>
                          setFieldValue(
                            "sms.consented",
                            (!eval(values?.sms?.consented)).toString()
                          ).toString()
                        }
                        disabled={
                          values.agreeAllConsent
                        }
                        label="SMS"
                        name="sms.consented"
                        checked={values.sms.consented === "true"}
                      />
                    </div>
                  </GridCol>
                )}

              {/* new Epost and SMS */}
              <GridCol m="12" l="12" xl="12">
                  <Label variant="body" width={"100%"}>
                    NAFs forsikringspartner kan kontakte meg for et godt medlemstilbud på forsikringer.

                  </Label>
                  <div className="radio-gap">
                    <RadioButton
                      label="Ja"
                      value="true"
                      onClick={handleChange}
                      disabled={
                        values.agreeAllConsent
                      }
                      checked={
                        values.useInformationConsent.consented === "true"
                      }
                      name="useInformationConsent.consented"
                    />
                    <RadioButton
                      label="Nei"
                      onClick={handleChange}
                      disabled={
                        values.agreeAllConsent
                      }
                      checked={
                        values.useInformationConsent.consented === "false"
                      }
                      name="useInformationConsent.consented"
                      value="false"
                    />
                  </div>
                </GridCol>
                {values.useInformationConsent.consented === "true" && (
                  <GridCol m="12" l="12" xl="12">
                    <Label variant="body" width={"100%"}>
                      Velg kanal:
                    </Label>
                    <div className="radio-gap">
                      <Checkbox
                        onChange={(value) =>
                          setFieldValue(
                            "ePostNew.consented",
                            (!eval(values?.ePostNew?.consented)).toString()
                          )
                        }
                        disabled={
                          values.agreeAllConsent
                        }
                        label="E-post"
                        name="ePostNew.consented"
                        checked={values.ePostNew.consented === "true"}
                      />
                      <Checkbox
                        onChange={(value) =>
                          setFieldValue(
                            "smsNew.consented",
                            (!eval(values?.smsNew?.consented)).toString()
                          ).toString()
                        }
                        disabled={
                          values.agreeAllConsent
                        }
                        label="SMS"
                        name="smsNew.consented"
                        checked={values.smsNew.consented === "true"}
                      />
                    </div>
                  </GridCol>
                )}


                <GridCol m="12" l="12" xl="12">
                  <Label variant="body" width={"100%"}>
                    Jeg godtar at NAF deler opplysninger om mitt medlemskap med
                    utvalgte samarbeidspartnere slik at de kjenner meg og kan gi
                    meg tilpassede tilbud hvis jeg kontakter dem.
                  </Label>
                  <div className="radio-gap">
                    <RadioButton
                      label="Ja"
                      value="true"
                      disabled={
                        values.agreeAllConsent
                      }
                      onClick={handleChange}
                      checked={
                        values.shareInformationConsent.consented === "true"
                      }
                      name="shareInformationConsent.consented"
                    />
                    <RadioButton
                      label="Nei"
                      onClick={handleChange}
                      disabled={
                        values.agreeAllConsent
                      }
                      checked={
                        values.shareInformationConsent.consented === "false"
                      }
                      name="shareInformationConsent.consented"
                      value="false"
                    />
                  </div>
                </GridCol>

              </>
            )}
          </GridRow>
        </Grid>
      </form>     
    </Style>
  );
});

export default Customer;
