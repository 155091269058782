import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CallOutlinedIcon from "@material-ui/icons/CallOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import LockOpenOutlined from "@material-ui/icons/LockOpenOutlined";
import { Text } from "@naf/text";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import Person from "@material-ui/icons/PersonOutline";
import useRootClose from 'react-overlays/useRootClose'
import { Grid, GridRow, GridCol } from "@naf/grid";
import { User } from "../context/userContext";
import {CustomerSearchService} from '../services/CustomerSearchService'
import Select from 'react-select';
import { DateUtil } from "../utils/DateUtils";



export default function IDLService(props){
    const {customerNumber, setVisibleIDL}= props;
    const [idlServices, setIDLService] = useState(null);

    const getIDLServices = ()=>{
    const customerSearchService = new CustomerSearchService();
    customerSearchService.getIDLServices(customerNumber).then((res) => {

        if (res?.success && res.result) {
            if(res.result.services?.length){
                setIDLService(res.result.services);
                setVisibleIDL(true);
            }
            else{
                setVisibleIDL(false);
            }
        }
      })
    }

    
  useEffect(() => {
    getIDLServices();
  }, []);

    return (
        <>
        {
              idlServices && idlServices.map((service,index) => (
                <div key={index}>
                    <div className="d-flex col-gap align-items-center justify-content-center m-1">
                    <Text style={{
                        margin: "0px",
                    }}
                        variant="bodyBold"
                    >
                       {service?.productName}
                    </Text>

                    </div>
                    {
                        service.productUsages.map((pusage,index) => (
                <div  className="mtop">
               
                    <div key={`productUsage-${index}`} className="d-flex col-gap align-items-center justify-content-between">
                    <Text
                        variant="bodyBold"
                        style={{
                        margin: "0px",
                        }}
                    >
                        Gyldig fra
                    </Text>
                    <Text
                        variant="details"
                        style={{
                        margin: "0px",
                        }}
                    >
                        {DateUtil.convertToDisplayFormat(
                        pusage.created
                        )}
                    </Text>
                    </div>

                    <div key={`productUsageenddate-${index}`} className="d-flex col-gap align-items-center justify-content-between">
                    <Text
                        variant="bodyBold"
                        style={{
                        margin: "0px",
                        }}
                    >
                        Gyldig til 
                    </Text>
                    <Text
                        variant="details"
                        style={{
                        margin: "0px",
                        }}
                    >
                        {DateUtil.convertToDisplayFormat(
                        pusage.endDate
                        )}
                    </Text>
                    </div>
                    <div key={`productUsagecomment-${index}`} className="d-flex col-gap align-items-center justify-content-between">
                    <Text
                        variant="bodyBold"
                        style={{
                        margin: "0px",
                        }}
                    >
                        Kommentar
                    </Text>
                    <Text
                        variant="details"
                        style={{
                        margin: "0px",
                        }}
                    >
                        {pusage.comment}
                    </Text>
                    </div>
                    </div>
                        ))
                    }  
                </div>

            )) 
        }
        </>
    )
  
}