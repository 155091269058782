import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

export default function ProtectedRoute(props) {
  const  isAuthenticated  = useIsAuthenticated();

  return (
    <Route
      path={props.path}
      render={(data) => {
        return isAuthenticated ? (
          <props.component {...data} {...props}></props.component>
        ) : (
          <Redirect to={{ pathname: "/home" }}></Redirect>
        );
      }}
    />
  );
}
