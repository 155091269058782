import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { UserProvider } from "./context/userContext";

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    authority: process.env.REACT_APP_AUTH0_DOMAIN,
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
});

ReactDOM.render(
  <MsalProvider instance={msalInstance} forceLogin={true}>
    <UserProvider>
      <Router>
        <App />
      </Router>
    </UserProvider>

  </MsalProvider>
  ,
  document.getElementById("root")
);
