import React, { useState, useEffect } from "react";
import "./styles/global.css";
import "./App.css";
import { GlobalStyle } from "@naf/global-style";
import { ThemeProvider } from "@naf/theme-provider";
import Membership from "./pages/Membership/Membership";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Redirect, Route, Switch } from "react-router-dom";
import Home from "./pages/Home/Home";
import ProtectedRoute from "./hoc/ProtectedRoute";
import AuthRoute from "./hoc/AuthRoute";
import { useToast, ToastVariant } from "@naf/toast";
import axios from "axios";
import { ValidateUserService } from "./services/ValidateUserService";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Button } from "@naf/button";
import { callMsGraph } from "./services/Graph";
import { User, UserProvider } from "./context/userContext";
import { Dialog } from "@naf/dialog";

export default function App() {
  const [reset, setReset] = useState(false);
  const { showToast, Toast } = useToast();
  const [isConsentRequired, setIsConsentRequired] = useState(true);
  const [reqIns, setReqIns] = useState(true);
  const [resIns, setResIns] = useState(true);
  const [signInError, setsignInError] = useState(false);

  const [user, setUser] = User();
  const validateUserService = new ValidateUserService();
  const { instance, inProgress, accounts } = useMsal();


  const logoutHandler = () => {
    localStorage.clear()
    instance.logout({
      returnTo: window.location.origin,
    });
  };

  const setSalesLocation=(data)=>{

    const salesLocation = JSON.parse(localStorage.getItem("salesLocation"))
    if(salesLocation){
      if(data){
      data.officeLocationNumber = salesLocation?.costCenter;
      }
    localStorage.setItem("officeLocationNumber", salesLocation?.costCenter);
    }
  }
  const requestProfileData = () => {

    instance.acquireTokenSilent({
      scopes: ["User.Read"],
      account: accounts[0]
    }).then((response) => {
      callMsGraph(response.accessToken).then(response => {
        if (response?.officeLocation && response.officeLocation.split(":")?.length === 2) {
          response.officeLocationNumber = response.officeLocation.split(":")[1];
          setSalesLocation(response);
        } 
        // else {
        //   setsignInError(true)
        // }
        setUser(response)
      });
    });
  }

  useEffect(() => {
    if (accounts?.length) {
      localStorage.setItem("email", accounts[0].username);
      requestProfileData();
    }
  }, [accounts]);

  useEffect(() => {
    if (user?.officeLocationNumber) {
      localStorage.setItem("officeLocationNumber", user?.officeLocationNumber);

    }
    setSalesLocation(null);
  }, [user])



  useEffect(() => {

    if (reqIns) {
      axios.interceptors.response.eject(reqIns);
    }
    if (resIns) {
      axios.interceptors.request.eject(resIns);
    }
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        return response?.data?.data ? response?.data?.data : response?.data ;
      },
      (error) => {
        const response = error?.response;
        if (response?.status !== 404) {
          showToast({
            variant: ToastVariant.Error,
            icon: true,
            message: "Oops! Noe gikk galt",
          });
        }

        if (response?.status === 401) {
          // instance.logout();
          return { success: false, result: null };
        }
        return { success: false, result: null };
      }
    );
    const requestInterceptor = axios.interceptors.request.use(
      async (request) => {


        const token = (await instance.acquireTokenSilent({
          // scopes: ["api://2b028198-a986-4110-95ad-958b712ca6fe/allapi"],
          scopes: [process.env.REACT_APP_LOGIN_SCOPE],
          account: instance.getAllAccounts()?.[0]
        }))?.accessToken;
        request.headers.Authorization = token ? `Bearer ${token}` : "";
        request.headers["Ocp-Apim-Subscription-Key"] =
          process.env.REACT_APP_SUBSCRIPTION_KEY;
        request.headers["Ocp-Apim-Trace"] = true;
        request.headers["x-office-location"] = localStorage.getItem("officeLocationNumber");
        return request;
      },
      (error) => {
        console.error(error);
        return { success: false, result: null };
      }
    );
    setReqIns(requestInterceptor);
    setResIns(responseInterceptor)
    return () => {
      axios.interceptors.response.eject(reqIns);
      axios.interceptors.request.eject(resIns);
    };
  }, []);

  if (inProgress !== 'none') {
    return <>  </>;
  }


  return (
    <ThemeProvider>

      <GlobalStyle />
      <Toast></Toast>

      <div className="app-container">
        <Header setReset={setReset} />
        <Dialog isOpen={signInError} handleClose={() => { setsignInError(false); logoutHandler() }} button={<>
          <Button onClick={() => { setsignInError(false); logoutHandler() }} variant="outline" style={{ float: "right" }}>Ok</Button>
        </>}>
          <div className="d-block">
            Vi kunne dessverre ikke logge deg på, send oss en henvendelse  <span>
              <a href={"https://forms.office.com/e/RrBMKQYBf3"} target="_blank">her</a>

            </span>.
          </div>

        </Dialog>
        <Switch>
          <AuthRoute path="/home" component={Home} />
          <ProtectedRoute
            isConsentRequired={isConsentRequired}
            path="/register"
            reset={reset}
            setReset={setReset}
            component={Membership}
          />
          <Route exact path="/" render={() => <Redirect to="/register" />} />
        </Switch>
      </div>
      <Footer />
    </ThemeProvider >
  );
}
