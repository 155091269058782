import axios from "axios";
export class CaseService {
  service = "case";



  createCase(payload) {
    const url = `${process.env.REACT_APP_BASE_URL}/${this.service}/create`;
    return axios.post(url, payload);
  }

  getCaseByCustomerNumber(customerNumber) {
    const url = `${process.env.REACT_APP_BASE_URL}/${this.service}/getcasesbycustomernumber`;

    return axios.get(url, {params: {customerNumber} });
  }

}
