import React, { useEffect, useState } from "react";
import { Grid, GridRow, GridCol } from "@naf/grid";
import { Text } from "@naf/text";
import styled from "styled-components";
import { NewsLetterService } from "../services/NewsletterService";
import { DateUtil } from "../utils/DateUtils";

const Style = styled.div``;


export default function NewsLetter(props) {

    const [newLetters, setNewsLetters] = useState([]);


    useEffect(() => {
        const newsLetterService = new NewsLetterService();
        if (props?.customerId) {
            newsLetterService.getNewsLetterByCustomerId(props.customerId).then(res => {
                if (res.result?.length) {
                    setNewsLetters(res.result);

                } else {
                    setNewsLetters([])
                }
            }).catch(res => {
                setNewsLetters([])
            })
        } else {
            setNewsLetters([])
        }

    }, [props.customerId])


    return (
        <>
            {newLetters?.length > 1 && <Text
                style={{
                    marginTop: "28px",
                    marginBottom: "18px",
                    paddingTop: "16px"
                }}
                variant="header3"
            >
                Kommunikasjon
            </Text>}
            {newLetters.map(newsLetter => {
                return <div key={newsLetter?.id} className="border p-1 d-flex flex-direction-column col-gap mt-1" >
                    <div className="d-flex col-gap">
                        <Text style={{
                            margin: "0px",
                        }}
                            variant="bodyBold"
                        >
                            {newsLetter?.subjectLine}

                        </Text>
                    </div>
                    <div className="d-flex  col-gap justify-content-between">
                        <div>
                          
                            <Text
                                variant="details"
                                style={{
                                    margin: "0px",
                                }}
                            >
                                {newsLetter?.assetType}
                            </Text>
                        </div>
                        <div>
                         
                            <Text
                                variant="details"
                                style={{
                                    margin: "0px",
                                }}
                            >
                                {DateUtil.convertToDisplayFormat(
                                    newsLetter?.activityDate
                                )}
                            </Text>
                        </div>

                    </div>
                    <div className="d-flex  col-gap align-items-center justify-content-between">
                        <Text
                            variant="details"
                            style={{
                                margin: "0px",
                                flex: "none"

                            }}
                        >
                          Lenke:
                        </Text>
                        <Text
                            variant="details"
                            style={{
                                margin: "0px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap"
                            }}
                        >
                            <a href={newsLetter?.emailWebLink} target="_blank">  {newsLetter?.emailWebLink}</a>
                        </Text>
                    </div>
                </div>
            })}
        </>)
}
