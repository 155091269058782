import React, { useState, useRef, useEffect, useCallback } from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";
import { Label, Phone, Message, Input } from "@naf/input";
import { Button } from "@naf/button";
import { Grid, GridRow, GridCol } from "@naf/grid";
import { Text } from "@naf/text";
import Customer from "../../components/Customer";
import Invoice from "../../components/Invoice";
import styled from "styled-components";
import { RadioButton } from "@naf/radio-button";
import Product from "../../components/Product";
import { CustomerService } from "../../services/CustomerService";
import { CustomerSearchService } from "../../services/CustomerSearchService";
import { CommonUtil } from "../../utils/CommonUtils";
import { ProductInfo } from "../../constant/product.constant";
import { DateUtil } from "../../utils/DateUtils";
import { cloneDeep, isEqual } from "lodash";
import Vehicle from "../Vehicles/Vehicles";
import { NewsLetterService } from "../../services/NewsletterService";
import { CaseService } from "../../services/CaseService";
import { Dialog } from '@naf/dialog';
import { InvoiceService } from "../../services/InvoiceService";
import { MasterService } from "../../services/MasterService";
import { useToast, ToastVariant } from "@naf/toast";
import { Chips } from '@naf/chips'
import Select from 'react-select';
import IDLService from "../../components/IDLService";
import NewsLetter from "../../components/Newletter";


const Style = styled.div`
  .custom-radio-button {
    border: none;
    padding: 0px;
  }
  .step {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    margin-top: 30px;
  }
  .main-header {
    padding-bottom: 45px;
  }
  .search-customer {
    margin-left: 16px;
  }
  .customer-info {
    margin-top: 10px;
  }
  .button-container {
    display: flex;
    gap: 10px;
    margin-top: 50px;
  }
  .step-container {
    margin-top: 65px;
  }
  .plan-detail-container {
    padding-top: 48px;
    padding-bottom: 40px;
  }
  .plan-point {
    align-self: center;
    margin-right: 15px;
  }
  .plan-point-container {
    margin-bottom: 10px;
  }
  .plan-button-container {
    margin-top: 28px;
  }

  .search-container {
    padding-bottom: 0px;
  }
  .feedback {
    margin-top: 40px;
    margin-bottom: 56px;
  }
  .member-info {
    padding-top: 12px;
    padding-left: 12px;
  }
  .RSPBprogressBar {
    margin-top: 16px;
  }
  .pb-1 {
    padding-bottom: 1rem;
  }
  .pl-1 {
    padding-left: 1rem;
  }
  .not-allowed {
    cursor: not-allowed;
    color: gray;
  }
  padding-top: 74px;
  .mtop{
    margin-top:7px;
  }
`;

export default function Membership(props) {
  const [currentStep, setCurrentStep] = useState(1);
  const [customerVisible, setCustomerVisible] = useState(false);
  const [customerMobileNo, setCustomerMobileNo] = useState({
    code: 47,
    number: "",
  });
  const [phoneNumberVisible, setphoneNumberVisible] = useState(true);
  const [showSalesLocationDialog, setshowSalesLocationDialog] = useState(null);

  const [customerNumberVisible, setCustomerNumberVisible] = useState(true);
  const [searchBy, setSearchBy] = useState("mobileNo");

  const [customerNumber, setCustomerNumber] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [activeMemberships, setActiveMemberships] = useState(null);

  const [initialValueOfCustomer, setInitialValueOfCustomer] = useState(null);
  const [cloneAllCustomers, setcloneAllCustomers] = useState(null);
  const [allCustomers, setAllCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [phoneNumberRequired, setphoneNumberRequired] = useState(false);
  const [plan, setPlan] = useState(1);
  const { showToast, Toast } = useToast();
  const [salesLocations, setSalesLocations] = useState(null);
  const [selectedSalesLocation, setSelectedSalesLocation] = useState(null);  

  const [loading, setLoading] = useState({
    customerSearchLoading: false,
    customerLoading: false,
    productLoading: false,
    invoiceLoading: false,
  });

  const [createCustomerLoading, setCreateCustomerLoading] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [lastSearch, setLastSearch] = useState({
    phone: null,
    name: null,
    email: null,
    customerNumber: null
  });

  const [upgradeMembOpen, setUpgradeMembOpen] = useState(false);
  const [downgradeMembOpen, setDowngradeMembOpen] = useState(false);
  const [cancelMembOpen, setcancelMembOpen] = useState(false);
  const [payInvoiceOpen, setPayInvoiceOpen] = useState(false);
  const [updateMembershipSuccessDialog, setUpdateMembershipSuccessDialog] = useState(false);
  const [createCustomerDialog, setCreateCustomerDialog] = useState(false);

  const [payInvoiceSuccessDialog, setPayInvoiceSuccessDialog] = useState(false);

  const [updateMembershipLoading, setUpdateMembershipLoading] = useState(false);
  const [payInvoiceLoading, setPpayInvoiceLoading] = useState(false);

  const [updateMembershipDesc, setUpdateMembershipDesc] = useState("");


  const [createCustomerMode, setCreateCustomerMode] = useState(false);
  const [hasIdlRecords, setVisibleIDL] = useState(true);
  
  const customerFormikRef = useRef(null);
  const customerService = new CustomerService();
  const customerSearchService = new CustomerSearchService();

  const resetData = () => {
    setCurrentStep(1);
    setCustomerVisible(false);
    setInitialValueOfCustomer(null);
    setAllCustomers(null);
    setInvoice(null);
    setSelectedCustomer(null)
    customerMobileNo.number && setphoneNumberVisible(false);
    resetSearch();
  }

  const resetSearch = () => {
    setCustomerMobileNo({ code: 47, number: "" });
    setCustomerNumber("");
    setCustomerEmail("");
    setCustomerName("");
    setphoneNumberVisible(false)
  }

  useEffect(() => {
    setphoneNumberVisible(true);
  }, [phoneNumberVisible]);

  useEffect(() => {
    if (props.reset) {
      resetData();
      props.setReset(false);
    }
  }, [props.reset]);

  useEffect(() => {
    const salesLocation = JSON.parse(localStorage.getItem("salesLocation"))
    if(!salesLocation){
        setshowSalesLocationDialog(true);
    }else{
  if(salesLocation){
    localStorage.setItem("officeLocationNumber", salesLocation?.costCenter);
  }
    setshowSalesLocationDialog(false);
    }
  }, [showSalesLocationDialog]);

  useEffect(() => {
    getSalesLocations();
}, [])


  const getSalesLocations = () => {
    const masterService = new MasterService();
      masterService.getSalesLocations().then((res) => {
        setSalesLocations(res.result.map(item=>  {
          return {
            value:item.id,
            label: `${item.name}`,
            costCenter: item.costCenter
          }
        }));

    
      })
  }
  const countries = CommonUtil.getCountries();

  const processCustomer = (customer) => {
    customer.shareInformationConsent = {
      consentNumber: "20",
      consented:
        customer.consents.find((consent) => consent.consentNumber === "20")
          ?.consented || "false",
    };
    customer.ePost = {
      consentNumber: "30",
      consented:
        customer.consents.find((consent) => consent.consentNumber === "30")
          ?.consented || "false",
    };
    customer.sms = {
      consentNumber: "40",
      consented:
        customer.consents.find((consent) => consent.consentNumber === "40")
          ?.consented || "false",
    };

    customer.ePostNew = {
      consentNumber: "120",
      consented:
        customer.consents.find((consent) => consent.consentNumber === "120")
          ?.consented || "false",
    };
    customer.smsNew = {
      consentNumber: "110",
      consented:
        customer.consents.find((consent) => consent.consentNumber === "110")
          ?.consented || "false",
    };
    customer.useInformationConsent = {
      consentNumber: "10",
      consented:
        customer.consents.find((consent) => consent.consentNumber === "10")
          ?.consented || "false",
    };
    customer.isMember = customer.subscriptions?.length ? true : false;

    if (customer?.basic?.dateOfBirth) {
      customer.basic.day = DateUtil.getDay(customer?.basic?.dateOfBirth);
      customer.basic.month = DateUtil.getMonth(customer?.basic?.dateOfBirth);
      customer.basic.year = DateUtil.getYear(customer?.basic?.dateOfBirth);
    }

    customer.agreeAllConsent =
      customer.shareInformationConsent?.consented === "true" &&
      customer.ePost?.consented === "true" &&
      customer.sms?.consented === "true" &&
      customer.ePostNew?.consented === "true" &&
      customer.smsNew?.consented === "true" &&
      customer.useInformationConsent?.consented === "true";

  };

  const getCustomerDetails = (customerId) => {
    if (customerId) {
      const customerService = new CustomerService();
      customerService.getMembershipByCustomerId(customerId).then(res => {
        if (res?.services?.length) {
          const MCProduct = res.services.find((ser) => ser.product?.id === "1601");
          if (MCProduct) {
            res.addOnsProduct = MCProduct;
          }
        }  
        setActiveMemberships(res);
      }).catch(res => {
        console.log(res)
      })
    }
  }

  const searchCustomer = useCallback((presectCustomerId = null, customerNum = null, useLastSearch = false) => {

    let queryParam = null
    if (customerNum) {
      queryParam = {
        customerNumber: customerNum
      }
    } else if (useLastSearch) {
      queryParam = lastSearch;
      setCustomerName(lastSearch.name || "")
      setCustomerEmail(lastSearch.customerEmail || "")
      setCustomerNumber(lastSearch.customerNumber || "")

      setCustomerMobileNo({
        code: 47,
        number: lastSearch.phone || "",
      })

    } else {
      if (!(customerMobileNo?.number || customerNumber || customerEmail || customerName)) {
        return
      }

      queryParam = {
        phone: customerMobileNo.number ? customerMobileNo.number : undefined,
        name: customerName ? customerName : undefined,
        email: customerEmail ? customerEmail : undefined,
        customerNumber: customerNumber ? customerNumber : undefined
      }
    }


    setLastSearch({ ...queryParam })

    setLoading({ ...loading, customerSearchLoading: true });
    customerSearchService
      .searchCustomer(queryParam)
      .then((res) => {
        setSelectedCustomer(null)
        setCreateCustomerMode(false)
        setLoading({
          ...loading,
          customerSearchLoading: false,
          customerLoading: false,
        });
        if (res?.success && res.result?.length) {
          const allCustomers = res.result;
          allCustomers.forEach((customer) => {
            processCustomer(customer);
          });
          let initialCustomer = null;
          if (presectCustomerId) {
            initialCustomer = allCustomers.find(
              (customer) => customer.customerId === presectCustomerId
            );           
            setSelectedCustomer(initialCustomer)
            setAllCustomers([initialCustomer]);
          }

          if (!initialCustomer) {
            initialCustomer = allCustomers && allCustomers.length ? allCustomers[0] : null;
            setAllCustomers(allCustomers);

          }
          if (customerNum && initialCustomer) {           
            setSelectedCustomer(initialCustomer)
          }
          getCustomerDetails(initialCustomer?.customerId);
          setInitialValueOfCustomer(initialCustomer);
          setcloneAllCustomers(cloneDeep(allCustomers));
        } else {
          setInitialValueOfCustomer(0);
          setAllCustomers([]);
          setcloneAllCustomers([]);
          setActiveMemberships(null);
          setCreateCustomerMode(true)
        }
        setphoneNumberRequired(false);
        setCustomerVisible(true);
      });
  });

  const refreshData = (data) => {
    setTimeout(() => {
      customerSearchService
        .searchCustomer(searchBy === "mobileNo" ? customerMobileNo.number : null, searchBy === "customerNo" ? customerNumber : null)
        .then((res) => {
          setLoading({ ...loading, customerLoading: false });
          if (res.success && res.result?.length) {
            const allCustomers = res.result;
            allCustomers.forEach((customer) => {
              processCustomer(customer);
            });
            const preSelectedCustomer = allCustomers.find(
              (customer) => customer.customerId === data.result.customerId
            );
            setInitialValueOfCustomer(preSelectedCustomer);
            getCustomerDetails(preSelectedCustomer.customerId);
            setAllCustomers(allCustomers);
            setcloneAllCustomers(allCustomers);
          }
        });
    }, 500);
  };



  const cancelMembership = () => {
    setUpdateMembershipLoading(true)
    const caseService = new CaseService();
    const payload = {
      category: 'LOGLVL10_2',
      mainReason: 'LOGLVL20_11',
      subReason: 'LOGLVL30_53',
      customerId: initialValueOfCustomer.customerId,
      customerNumber: initialValueOfCustomer.customerNumber,
      description: updateMembershipDesc,
      status: "open",
      title: 'Avslutte medlemskap'

    }
    caseService.createCase(payload).then(data => {
      setcancelMembOpen(false)
      setUpdateMembershipLoading(false)
      setUpdateMembershipDesc("")

      setUpdateMembershipSuccessDialog(true)
    }).catch(data => {
      setcancelMembOpen(false)
      setUpdateMembershipLoading(false)

      setUpdateMembershipDesc("")

    })
  }

  const downGradeMembership = () => {
    setUpdateMembershipLoading(true)
    const caseService = new CaseService();
    const payload = {
      category: 'LOGLVL10_5',
      mainReason: 'LOGLVL20_22',
      subReason: 'LOGLVL30_95',
      customerId: initialValueOfCustomer.customerId,
      customerNumber: initialValueOfCustomer.customerNumber,
      description: updateMembershipDesc,
      status: "open",
      title: 'Nedgradere medlemskap'


    }

    caseService.createCase(payload).then(data => {
      setUpdateMembershipLoading(false)
      setDowngradeMembOpen(false)
      setUpdateMembershipSuccessDialog(true)

      setUpdateMembershipDesc("")

    }).catch(data => {
      setUpdateMembershipLoading(false)
      setDowngradeMembOpen(false)
      setUpdateMembershipDesc("")

    })
  }
  const upgradeMembership = () => {
    setUpdateMembershipLoading(true)
    const caseService = new CaseService();
    const payload = {
      category: 'LOGLVL10_5',
      mainReason: 'LOGLVL20_22',
      subReason: 'LOGLVL30_94',
      customerId: initialValueOfCustomer.customerId,
      customerNumber: initialValueOfCustomer.customerNumber,
      description: updateMembershipDesc,
      status: "open",
      title: 'Oppgradere medlemskap'
    }

    caseService.createCase(payload).then(data => {
      setUpdateMembershipLoading(false)
      setUpgradeMembOpen(false);
      setUpdateMembershipDesc("")
      setUpdateMembershipSuccessDialog(true)

    }).catch(data => {
      setUpdateMembershipDesc("")
      setUpdateMembershipLoading(false)
      setUpgradeMembOpen(false);


    })
  }


  const payInvoice = () => {
    const invoiceService = new InvoiceService();
    setPpayInvoiceLoading(true)
    invoiceService.markInvoiceAsPaid({
      customerId: initialValueOfCustomer.customerId,
      customerNumber: initialValueOfCustomer.customerNumber,
      invoiceId: activeMemberships?.invoices[0]?.invoiceId
    }).then(data => {
      setTimeout(() => {
        setPpayInvoiceLoading(false)
        setPayInvoiceOpen(false)
        setPayInvoiceSuccessDialog(true)
        getCustomerDetails(initialValueOfCustomer.customerId);
      },3000);
    }).catch(data => {
      setPpayInvoiceLoading(false)
      setPayInvoiceOpen(false)

    })
  }

  const handleCustomer = () => {
    const errors = customerFormikRef.current.errors;
    console.log(errors)
    if (customerFormikRef.current && Object.keys(errors).length) {
      customerFormikRef.current.setTouched(errors);
      return;
    }
    const value = customerFormikRef.current.values;
    const oldCustomerValue = cloneAllCustomers.find(
      (customer) => customer.customerNumber === value.customerNumber
    );

    function isConsentUpdate(newValue, previousValue) {
      if (
        newValue.useInformationConsent.consented !=
          previousValue.useInformationConsent.consented ||
        newValue.shareInformationConsent.consented !=
          previousValue.shareInformationConsent.consented ||
        newValue.ePost.consented != previousValue.ePost.consented ||
        newValue.sms.consented != previousValue.sms.consented ||
        newValue.ePostNew.consented != previousValue.ePostNew.consented ||
        newValue.smsNew.consented != previousValue.smsNew.consented ||
        newValue.agreeAllConsent != previousValue.agreeAllConsent
      ) {
        return true;
      } else {
        return false;
      }
    }
    function isProfileUpdate(newValue, previousValue) {
      if (
        !isEqual(newValue.basic, previousValue.basic) ||
        !isEqual(newValue.address, previousValue.address)
      ) {
        return true;
      } else {
        return false;
      }
    }


    function generateConsentRequest(customerId, consentData) {
      return {
        CustomerId: customerId,
        ConsentUpdateRequest: {
          Consents: consentData,
          Source: "CRM Portal",
        },
      };
    }

    function getUpdatedConsents(originalConsents, updatedConsents) {
      const differences = [];
    
      updatedConsents.forEach(updatedConsent => {
        const originalConsent = originalConsents.find(consent => consent.consentNumber === updatedConsent.consentNumber);
    
        if (!originalConsent || originalConsent.consented !== updatedConsent.consented) {
          differences.push(updatedConsent);
        }
      });  
      return differences;
    }

    if (
      oldCustomerValue &&
      value.customerNumber &&
      isEqual(oldCustomerValue, value)
    ) {
      return;
    }
    value.consents = [
      value.shareInformationConsent,
      value.ePost,
      value.sms,
      value.ePostNew,
      value.smsNew,
      value.useInformationConsent,
    ];
    value.basic.dateOfBirth = (value.basic.day && value.basic.month && value.basic.year) ? DateUtil.convertToStringDate(
      value.basic.day,
      value.basic.month,
      value.basic.year
    ) : null;

    setLoading({ ...loading, customerLoading: true });
    if (value.customerNumber) {
      if (
        (isConsentUpdate(value, initialValueOfCustomer) &&
          isProfileUpdate(value, initialValueOfCustomer)) ||
        isProfileUpdate(value, initialValueOfCustomer)
      ) {
        customerService.updateCustomer({ customer: value }).then(
          (data) => {           
            if (data.success) {
              setTimeout(() => {
                searchCustomer(initialValueOfCustomer.customerId);
                showToast({
                  variant: ToastVariant.Success,
                  icon: true,
                  message: "Kunde ble oppdatert",
                });
              }, 4000);
            }
          },
          () => setLoading({ ...loading, customerLoading: false })
        );
      } else {

        var updatedConsents= getUpdatedConsents(initialValueOfCustomer.consents,value.consents);
        const formattedConsentData = updatedConsents.map((consent) => ({
          Consented: consent.consented,
          ConsentId: consent.consentNumber,
        }));
        const consentRequests = generateConsentRequest(
          value.customerId,
          formattedConsentData
        );
        customerService.updateConsent(consentRequests).then(         
          (data) => {           
            if (data.success) {              
              setTimeout(() => {
                searchCustomer(initialValueOfCustomer.customerId);
                showToast({
                  variant: ToastVariant.Success,
                  icon: true,
                  message: "Kunde ble oppdatert",
                });
              }, 4000);
            }
          },
          () => setLoading({ ...loading, customerLoading: false })
        );
      }
    } else {
      customerService.createCustomer({ customer: value }).then(
        (data) => {
          if (data.success) {
            resetSearch();
            setCustomerNumber(data.result?.customerNumber);
            setTimeout(() => {
              searchCustomer(null, data.result?.customerNumber);
              showToast({
                variant: ToastVariant.Success,
                icon: true,
                message: "Kunde opprettet",
              });

              setCreateCustomerMode(false);
              setCreateCustomerDialog(false);
              setCreateCustomerLoading(false);
            }, 4000);
          } else {
            setCreateCustomerLoading(false);

            setLoading({ ...loading, customerLoading: false });
            setCreateCustomerMode(false);
          }
        },
        () => setLoading({ ...loading, customerLoading: false })
      );
    }
  };

  const step1 = () => {
    if (currentStep !== 1) {
      searchCustomer(null, initialValueOfCustomer.customerNumber, false);
    }
    setCurrentStep(1)
  }

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        searchCustomer()
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [searchCustomer]);

  const handleSelection = () => {
    if (!initialValueOfCustomer.customerNumber) {    
      setSelectedCustomer(initialValueOfCustomer);
      setCreateCustomerDialog(true)
    } else {     
      setSelectedCustomer(initialValueOfCustomer);    
      setAllCustomers(allCustomers.filter(customer => customer.customerNumber === initialValueOfCustomer.customerNumber))
    }
  }

  const createCustomer = () => {
    setCreateCustomerLoading(true);
    handleCustomer()
  }

  return (
    <Style>
      <Toast></Toast>

      <Text
        style={{
          margin: 0,
        }}
        tag="h1"
        variant="header1"
        className="main-header"
      >
        CRM Portalen
      </Text>
      {salesLocations && <Dialog  closeIcon={false} style={{padding:"20px"}} isOpen={showSalesLocationDialog}
      handleClose={() => {}} title={'Før du går videre må du velge salgssted i nedtrekksmenyen.'} button={<>
                    <Button  disabled={!selectedSalesLocation} onClick={()=>{
                      setshowSalesLocationDialog(false);
                      window.location.reload(false);
                    }}
                      >Ok</Button>
                  </>}>
                  {
                          
                           <Select placeholder="Velg" menuPosition="menuPosition" maxMenuHeight={150} menuShouldScrollIntoView={true}  isSearchable={true}  options={salesLocations}
                          onChange={(val)=>{
                            setSelectedSalesLocation(val);
                            localStorage.setItem("officeLocationNumber", val?.costCenter);
                            localStorage.setItem("salesLocation",JSON.stringify(val))}}
                           /> 
                        }
                    {/* <Input onChange={val => setUpdateMembershipDesc(val)} value={updateMembershipDesc} as="textarea" /> */}
                  </Dialog>
                  }
      <div>
      
        <Grid>
          <GridRow columns="12">
            <GridCol m="4" l="4" xl="4" className="cusor-pointer">
              <div className="d-flex">
                <Text
                  style={{
                    margin: 0,
                  }}
                  variant={currentStep === 1 ? "bodyHeader" : "body"}
                  onClick={() => step1()}
                >
                  Kunde

                </Text>
                {initialValueOfCustomer && <Text
                  style={{
                    margin: 0,
                  }}
                  variant={"bodyMedium"}
                  onClick={() => step1()}
                >
                  :   &nbsp;
                  {initialValueOfCustomer?.basic?.firstName}   {initialValueOfCustomer?.basic?.lastName} ({initialValueOfCustomer?.customerNumber})
                </Text>}
              </div>

              <ProgressBar
                percent={currentStep === 1 ? 100 : 0}
                filledBackground="rgb(123, 219, 131)"
              />
            </GridCol>
            <GridCol m="4" l="4" xl="4" >
              <Text
                style={{
                  margin: 0,
                }}
                className={initialValueOfCustomer?.customerId && selectedCustomer ? "cusor-pointer" : "not-allowed"}
                variant={currentStep === 2 ? "bodyHeader" : "body"}
                onClick={() => initialValueOfCustomer?.customerId && selectedCustomer ? setCurrentStep(2) : ""}

              >
                Tjenester
              </Text>
              <ProgressBar
                percent={currentStep === 2 ? 100 : 0}
                filledBackground="rgb(123, 219, 131)"
              />
            </GridCol>
            <GridCol m="4" l="4" xl="4" >
              <Text
                style={{
                  margin: 0,
                }}
                className={initialValueOfCustomer?.customerId && selectedCustomer ? "cusor-pointer" : "not-allowed"}

                variant={currentStep === 3 ? "bodyHeader" : "body"}
                onClick={() => initialValueOfCustomer?.customerId && selectedCustomer ? setCurrentStep(3) : ""}

              >
                Medlemskap
              </Text>
              <ProgressBar
                percent={currentStep === 3 ? 100 : 0}
                filledBackground="rgb(123, 219, 131)"
              />
            </GridCol>
          </GridRow>
        </Grid>


        <div>
          <Grid className="step-container">
            {currentStep === 1 && (
              <>

                <GridRow columns="12" className="search-container ">
                  <GridCol m="8" l="8" xl="8">
                    <Text
                      tag="h2"

                      variant="header2"
                    >
                      Søk kunde
                    </Text>
                  </GridCol>
                  <GridCol m="6" l="6" xl="6">
                    <Label >Mobilnummer</Label>
                    {phoneNumberVisible &&
                      <Phone width={'full'}
                        onChange={(nr) => { setCustomerMobileNo(nr); }}
                        placeholder="8 siffer"
                        pattern="[0-9]+"
                        minlength="6"
                        maxlenght="6"
                        countries={countries}
                        value={customerMobileNo.number}
                        selectedCountry={countries[3]}
                        error={
                          phoneNumberRequired &&
                          !CommonUtil.validatePhoneNumber(
                            customerMobileNo.number
                          ).valid
                        }
                      />}
                  </GridCol>
                  <GridCol m="6" l="6" xl="6">

                    <Label>Kundenummer</Label>

                    <Input width={'full'}
                      onChange={(value) => setCustomerNumber(value)}
                      value={customerNumber || ""}
                      name="customerNumber"
                    />
                  </GridCol>

                  <GridCol m="6" l="6" xl="6">
                    <Label>Fornavn og etternavn</Label>
                    <Input width={'full'} onChange={(value) => setCustomerName(value)}
                      value={customerName || ""}
                      name="customerName" />

                  </GridCol>
                  <GridCol m="6" l="6" xl="6">
                    <Label>E-post</Label>
                    <Input width={'full'} onChange={(value) => setCustomerEmail(value)}
                      value={customerEmail || ""}
                      name="customerEmail" />

                  </GridCol>
                  <GridCol m="12" l="12" xl="12">
                    <div className="d-flex justify-content-end col-gap ">
                      <Button
                        onClick={resetData}
                        variant="outline"
                        size="small"                       
                      >Tøm skjema</Button>
                      <Button
                        onClick={searchCustomer}
                        text="Handling"
                        variant="secondary"
                        isLoading={loading.customerSearchLoading}
                        type="button"
                        size="small"
                        disabled={!customerMobileNo?.number && !customerName && !customerEmail && !customerNumber}
                        className="search-customer"
                      >
                        Søk
                      </Button>

                    </div>

                    {phoneNumberRequired &&
                      !CommonUtil.validatePhoneNumber(customerMobileNo.number)
                        .valid && (
                        <Message error>
                          {
                            CommonUtil.validatePhoneNumber(
                              customerMobileNo.number
                            ).message
                          }
                        </Message>
                      )}
                  </GridCol>         </GridRow>

              </>
            )}

            {currentStep === 1 && customerVisible && (
              <>
                <GridRow columns="12">
                  <GridCol m="6" l="6" xl="6">
                    <div className="select-customer-container">
                      <Text
                        tag="h2"
                        style={{
                          marginTop: "38px",
                          marginBottom: "24px",
                        }}
                        variant="header2"
                      >
                        Velg kunde
                      </Text>

                      {allCustomers.map((customer) => (
                        <>


                          <div className="customer-info">
                            <div
                              className="member-info border"
                              style={{ padding: "0.6em" }}
                              onClick={() => {
                                setInitialValueOfCustomer(customer);
                                getCustomerDetails(customer.customerId);
                              }}
                            >
                              <div className="d-flex">
                                <RadioButton
                                  label={`${customer?.basic?.firstName} ${customer?.basic?.lastName} 
                                    ${customer?.customerNumber
                                      ? "- " + customer?.customerNumber
                                      : ""
                                    } 
                                  `}
                                  name="customer"
                                  checked={initialValueOfCustomer === customer}
                                  width="full"
                                  outline
                                  className="custom-radio-button"
                                />

                                {initialValueOfCustomer === customer && !selectedCustomer &&

                                  <Button
                                    onClick={() => handleSelection()}                                  
                                    style={{ marginRight: "1em" }}
                                    size="small"                                    
                                  >Velg</Button>
                                }

                              </div>


                              <div className="d-flex">
                                <Text
                                  style={{
                                    marginTop: "0px",
                                    marginRight: "20px",
                                    marginLeft: "32px",
                                  }}
                                  variant="details"
                                >
                                  {customer?.address?.streetLine1}    {customer?.address?.city}
                                </Text>


                              </div>
                            </div>
                          </div>

                        </>
                      ))}

                      {!selectedCustomer && <div
                        className="customer-info"
                        onClick={() => {
                          setInitialValueOfCustomer(null)
                          setActiveMemberships(null)
                          setCreateCustomerMode(true)
                        }}
                      >
                        <RadioButton
                          label="Opprett ny kunde"
                          name="customer"
                          value={0}
                          width="full"
                          checked={!initialValueOfCustomer}
                          outline
                        />

                      </div>}


                    </div>


                    {(selectedCustomer || createCustomerMode) && <>
                      <Customer
                        isConsentRequired={props?.isConsentRequired}
                        mobilePhone={customerMobileNo.number}
                        ref={customerFormikRef}
                        initialValueOfCustomer={initialValueOfCustomer}
                      />
                      <div className="button-container">
                        <Button
                          onClick={resetData}
                          variant="outline"
                          size="small"                          
                        >Tilbake</Button>
                        {
                          <Button
                            onClick={handleCustomer}                           
                            size="small"                          
                            isLoading={loading.customerLoading}
                          >{initialValueOfCustomer ? 'Oppdater' : 'Lagre'}</Button>
                        }
                      </div>  
                      <NewsLetter customerId={initialValueOfCustomer?.customerId} />
                      </> }
                  </GridCol>
                  {selectedCustomer && (activeMemberships?.membership || hasIdlRecords) && <GridCol m="6" l="6" xl="6">
                    <div className={!hasIdlRecords ? "p-2 d-flex flex-direction-column col-gap":"border p-2 d-flex flex-direction-column col-gap"} style={{ marginTop: '5.4em', marginLeft: '3em' }}>

                    {activeMemberships?.membership && selectedCustomer &&  <div className="d-flex col-gap align-items-center justify-content-center m-1">
                        <Text style={{
                          margin: "0px",
                        }}
                          tag="p"
                          variant="bodyTextHeader"
                        >
                          {activeMemberships?.membership?.product?.name}
                        </Text>

                        {/* TODO: The chip shows the incorrect membership status. Removed until this can be fixed. */}
                        {/* {activeMemberships?.membership?.status === 1 || activeMemberships?.membership?.status === 4?
                          <Chips
                            style={{
                              padding: " 3px 10px 3px 10px",
                              background:"rgb(123, 219, 131)"
                            }}
                            text="Aktiv"                           
                          />
                          : activeMemberships?.membership?.status !== 3 && (
                           <Chips
                            style={{
                              padding: " 3px 10px 3px 10px",
                              background: "rgb(192, 0, 21)",
                              color: "white",

                            }}
                            text="Utgått "
                            variant="primary"
                          />)} */}
                          <div />

                      </div>}


                      {activeMemberships?.membership && selectedCustomer &&  <div className="d-flex col-gap align-items-center justify-content-between">
                        <Text                        
                          style={{
                            margin: "0px",
                          }}
                          tag="p"
                          variant="bodyTextHeader"
                        >
                          Medlem siden
                        </Text>
                        <Text
                          variant="details"
                          style={{
                            margin: "0px",
                          }}
                        >
                          {DateUtil.convertToDisplayFormat(
                            activeMemberships?.membership?.membershipSignUpDate
                          )}
                        </Text>
                      </div>}
                      {activeMemberships?.membership && selectedCustomer && (activeMemberships?.membership?.status === 1 || activeMemberships?.membership?.status === 4) &&   <div className="d-flex col-gap align-items-center justify-content-between">
                        <Text
                           tag="p"
                           variant="bodyTextHeader"
                          style={{
                            margin: "0px",
                          }}
                        >
                          Gyldig til
                        </Text>
                        <Text
                          variant="details"
                          style={{
                            margin: "0px",
                          }}
                        >
                          {DateUtil.convertToDisplayFormat(activeMemberships?.membership?.validUntil)}
                        </Text>
                      </div>}

                      {activeMemberships?.membership && selectedCustomer && activeMemberships?.customer?.customerRoleName === "HouseHoldMember" &&   <div className="d-flex col-gap align-items-center justify-content-between">
                        <Text
                          tag="p"
                          variant="bodyTextHeader"
                          style={{
                            margin: "0px",
                          }}
                        >
                          Medlemskapstype
                        </Text>
                        <Text
                          variant="details"
                          style={{
                            margin: "0px",
                          }}
                        >                           
                           {activeMemberships?.relatedCustomers?.find(customer => customer?.customerRoleName === "MainHouseHoldMember")?.customerRoleName === "MainHouseHoldMember" ? "Husstandsmedlemskap" : undefined}

                        </Text>
                      </div>}

                      {activeMemberships?.membership && selectedCustomer && activeMemberships?.customer?.customerRoleName === "HouseHoldMember" &&  <div className="d-flex col-gap align-items-center justify-content-between">
                        <Text
                           tag="p"
                          variant="bodyTextHeader"
                          style={{
                            margin: "0px",
                          }}
                        >
                          Hovedmedlem
                        </Text>
                        <Text
                          variant="details"
                          style={{
                            margin: "0px",
                          }}
                        >
                          {activeMemberships?.relatedCustomers?.find(customer => customer?.customerRoleName === "MainHouseHoldMember")?.fullName}
                        </Text>
                      </div>}                     

                      {
                        activeMemberships?.membership && selectedCustomer && activeMemberships?.addOnsProduct && <div >
                          <div className="d-flex col-gap align-items-center justify-content-center m-1">
                            <Text style={{
                              margin: "0px",
                            }}
                            tag="p"
                          variant="bodyTextHeader"
                            >
                              {activeMemberships?.addOnsProduct?.product?.name}
                            </Text>

                            {activeMemberships?.membership?.status === 1 || activeMemberships?.membership?.status === 4 ?
                              <Chips
                                style={{
                                  padding: " 3px 10px 3px 10px",
                                }}
                                text="Aktiv"
                                variant="primary"
                              />
                              :activeMemberships?.membership?.status !== 3 && (
                               <Chips
                                style={{
                                  padding: " 3px 10px 3px 10px",
                                  background: "rgb(192, 0, 21)",
                                  color: "white",
                                }}
                                text="Utgått "
                                variant="primary"
                              />)}

                          </div>
                          <div className="d-flex col-gap align-items-center justify-content-between">
                            <Text
                              tag="p"
                          variant="bodyTextHeader"
                              style={{
                                margin: "0px",
                              }}
                            >
                              Medlem siden
                            </Text>
                            <Text
                              variant="details"
                              style={{
                                margin: "0px",
                              }}
                            >
                              {DateUtil.convertToDisplayFormat(
                                activeMemberships?.addOnsProduct?.signupDate
                              )}
                            </Text>
                          </div>
                          {(activeMemberships?.membership?.status === 1 || activeMemberships?.membership?.status === 4) && 
                          <div className="d-flex col-gap align-items-center justify-content-between">
                            <Text
                               tag="p"
                          variant="bodyTextHeader"
                              style={{
                                margin: "0px",
                              }}
                            >
                              Gyldig til
                            </Text>
                            <Text
                              variant="details"
                              style={{
                                margin: "0px",
                              }}
                            >
                              {DateUtil.convertToDisplayFormat(activeMemberships?.addOnsProduct?.validUntil)}
                            </Text>
                          </div>
                          }
                        </div>
                      }

                      {/* Pay invoice feature does not work. Must be fixed before it can be used. */}
                      {/* {activeMemberships?.membership && selectedCustomer && activeMemberships?.membership?.hasUnpaidInvoice && <div className="d-flex col-gap align-items-center justify-content-between">
                        <Text
                         tag="p"
                          variant="bodyTextHeader"
                          style={{
                            margin: "0px",
                          }}
                        >
                          Ubetalt faktura
                        </Text>

                        <Button
                          onClick={() => setPayInvoiceOpen(true)}
                          variant="primary"
                          size="small"  
                        >Betal</Button>
                      </div>
                      } */}
                    <IDLService customerNumber={selectedCustomer.customerNumber} setVisibleIDL={setVisibleIDL}></IDLService>

                  {  activeMemberships?.membership && selectedCustomer && <div className="d-flex justify-content-between mt-1 col-gap" >
                        <Button
                          onClick={() => { setUpgradeMembOpen(true); setUpdateMembershipDesc("") }}                         
                          size="small"
                          className="p-1"                          
                          disabled={activeMemberships?.membership?.hasUnpaidInvoice}
                          style={{ height: '2.5em' }}
                        >Oppgrader medlemskap</Button>

                        <Button
                          onClick={() => { setDowngradeMembOpen(true); setUpdateMembershipDesc("") }}                        
                          size="small"
                          className="p-1"                          
                          disabled={activeMemberships?.membership?.hasUnpaidInvoice}
                          style={{ height: '2.5em' }}

                        >Nedgrader medlemskap</Button>

                        <Button
                          onClick={() => { setcancelMembOpen(true); setUpdateMembershipDesc("") }}                       
                          size="small"
                          className="p-1"                        
                          style={{ height: '2.5em' }}

                        >Avslutt medlemskap</Button>

                      </div>}
                    </div>

                  </GridCol>}

                  

                  <Dialog isOpen={upgradeMembOpen} handleClose={() => setUpgradeMembOpen(false)} title={'Oppgrader medlemskap'} button={<>
                    <Button  onClick={() => setUpgradeMembOpen(false)} variant="outline">Avbryt</Button>
                    <Button  disabled={!updateMembershipDesc}
                      isLoading={updateMembershipLoading} onClick={() => upgradeMembership()}>Bestill oppgradering</Button>
                  </>}>
                    <Label >Oppgi hvilket medlemskap kunden ønsker å oppgraderes til </Label>
                    <Input onChange={val => setUpdateMembershipDesc(val)} value={updateMembershipDesc} as="textarea" />
                  </Dialog>

                  <Dialog isOpen={downgradeMembOpen} handleClose={() => setDowngradeMembOpen(false)} title={'Nedgrader medlemskap'} button={<>
                    <Button  onClick={() => setDowngradeMembOpen(false)} variant="outline">Avbryt</Button>
                    <Button disabled={!updateMembershipDesc} isLoading={updateMembershipLoading} onClick={() => downGradeMembership()}>Nedgrader medlemskap</Button>
                  </>}>
                    <Label > Oppgi hvilket medlemskap kunden ønsker å nedgradere til </Label>
                    <Input onChange={val => setUpdateMembershipDesc(val)} value={updateMembershipDesc} as="textarea" />
                  </Dialog>

                  <Dialog isOpen={cancelMembOpen} handleClose={() => setcancelMembOpen(false)} title={'Avslutt medlemskap'} button={<>
                    <Button onClick={() => setcancelMembOpen(false)} variant="outline" >Avbryt</Button>
                    <Button  disabled={!updateMembershipDesc} isLoading={updateMembershipLoading} onClick={() => cancelMembership()}>Avslutt medlemskap</Button>
                  </>}>
                    <Label > Oppgi hvilke medlemskap produkt(er) som skal avsluttes </Label>
                    <Input onChange={val => setUpdateMembershipDesc(val)} value={updateMembershipDesc} placeholder="Note" as="textarea" />
                  </Dialog>

                  <Dialog isOpen={payInvoiceOpen} handleClose={() => setPayInvoiceOpen(false)} button={<>
                    <Button onClick={() => setPayInvoiceOpen(false)} variant="outline">Avbryt</Button>
                    <Button isLoading={payInvoiceLoading} disabled={activeMemberships?.invoices?.length !== 1}  onClick={() => payInvoice()}>Betal</Button>
                  </>}>
                    {activeMemberships?.invoices?.length === 1 ? <>
                      Ønsker kunden å betale utestående faktura?
                      <Text
                        style={{ margin: "0px", marginTop: "1em" }}
                        variant="bodyHeader"
                      >
                        Beløp: {activeMemberships?.invoices[0].totalPrice},-
                      </Text>
                    </> :
                      <>
                        <div>
                          Kunden har mer enn 1 utestående faktura.

                        </div>
                        Vennligst kontakt kundesenteret.
                      </>

                    }
                  </Dialog>

                  <Dialog isOpen={updateMembershipSuccessDialog} handleClose={() => setUpdateMembershipSuccessDialog(false)} button={<>
                    <Button  onClick={() => setUpdateMembershipSuccessDialog(false)} variant="outline" style={{ float: "right" }}>Ok</Button>
                  </>}>
                    Melding er sendt til kundesenteret.
                    Det kan ta noen dager før endringen er utført
                  </Dialog>


                  <Dialog isOpen={createCustomerDialog} handleClose={() => {
                    setCreateCustomerDialog(false);
                    setSelectedCustomer(null)
                  }} button={<>
                    <Button  onClick={() => {
                      setCreateCustomerDialog(false);
                      setSelectedCustomer(null)

                    }} variant="outline">Avbryt</Button>
                    <Button isLoading={createCustomerLoading}  onClick={() => createCustomer()}>Lagre</Button>
                  </>}>
                    Kunden finnes ikke i NAF sitt kunderegister. Ønsker du å opprette kunden?
                  </Dialog>

                  <Dialog isOpen={payInvoiceSuccessDialog} handleClose={() => setPayInvoiceSuccessDialog(false)} button={<>
                    <Button  onClick={() => setPayInvoiceSuccessDialog(false)} variant="outline" style={{ float: "right" }}>Ok</Button>
                  </>}>
                    Melding om at faktura er betalt er sendt inn.
                    Det kan ta noe tid før det vises i CRM Portalen.
                    Husk å registrere beløpet i kassa
                  </Dialog>


                </GridRow>
              </>
            )}
            {selectedCustomer && <> {initialValueOfCustomer && (
              <div className={currentStep === 2 ? '' : 'd-none'}>
                <Vehicle customer={initialValueOfCustomer} activeMembership={activeMemberships} />
              </div>
            )}
              {initialValueOfCustomer && activeMemberships && (
                <div className={currentStep === 3 ? "" : 'd-none'} style={{ marginTop: "-39px" }}>
                  <Invoice customer={initialValueOfCustomer} membership={activeMemberships} />
                </div>
              )}
            </>}
          </Grid>
        </div>
      </div>
    </Style>
  );
}
