import { useContext, useState } from "react";

import React from "react";

const UserContext = React.createContext();

export function User() {
    return useContext(UserContext);
}
export function UserProvider({ children }) {

    const [user, setUser] = useState(null);

    return <UserContext.Provider value={[user, setUser]}>
        {children}
    </UserContext.Provider>
}