import { Button } from "@naf/button";
import { Text } from "@naf/text";
import React from "react";
import styled from "styled-components";
import { Grid, GridRow, GridCol } from "@naf/grid";
import membership from "../../assets/images/membership.svg";
import { TextList, TextListItem } from "@naf/text-list";
import { Accordion } from "@naf/accordion";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

const Style = styled.div`
  padding-top: 64px;
  margin-bottom: -10px;
  .header-details {
    margin-top: 16px;
    margin-bottom: 32px;
  }
  .step-container {
    margin-top: 8px;
    margin-bottom: 40px;
  }
  .step-text-list {
    padding-left: 19px;
  }
  .questions-container {
    margin-top: 55px;
    margin-left: -13vw;
    margin-right: -13vw;
    padding-top: 103px;
    padding-bottom: 103px;
    padding-left: 160px;
    background: #e9f3e6;
    left: 0;
  }
`;
export default function Home() {
  const { instance } = useMsal();

  const loginWithRedirect = () => {
    instance.loginRedirect({ scopes: [process.env.REACT_APP_LOGIN_SCOPE] })
  }
  return (
    <Style>
      <Text
        style={{
          margin: 0,
        }}
        variant="display"
      >
        Velkommen til CRM-portalen
      </Text>
      <Grid>
        <GridRow columns="12">
          <GridCol m="6" l="6" xl="6" s="12">
            <div className="header-details">
              <Text
                style={{
                  margin: 0,
                }}
                variant="ingress"
              >
                NAF sitt CRM system i forenklet utgave
              </Text>
              <Text
                style={{
                  margin: 0,
                }}
                variant="ingress"
              >
                Her kan du opprette medlemskap i NAF og søke etter eksisterende
                NAF-medlemmer.
              </Text>
            </div>
            <Text
              style={{
                margin: 0,
              }}
              variant="header3"
            >
              Slik gjør du:
            </Text>
            <div className="step-container">
              <TextList className="step-text-list" variant="number">
                <TextListItem>
                  Logg inn med NAF e-postadressen din og passordet som du bruker for pålogging til din PC (om du blir bedt om det)
                </TextListItem>

              </TextList>
            </div>
            <Button variant="primary" size="small" onClick={loginWithRedirect}>
              Logg inn
            </Button>
          </GridCol>
          <GridCol m="6" l="6" xl="6" s="12">
            <div className="d-flex justify-content-end">
              <img
                style={{ alignSelf: "start" }}
                alt="member"
                src={membership}
              />
            </div>
          </GridCol>
        </GridRow>
      </Grid>
      <div className="questions-container">
        Har du problemer med pålogging, send oss en henvendelse  <a href={"https://forms.office.com/e/RrBMKQYBf3"} target="_blank">her</a>.
      </div>
    </Style>
  );
}
