import React, { useEffect, useState } from "react";
import { Grid, GridRow, GridCol } from "@naf/grid";
import { Text } from "@naf/text";
import styled from "styled-components";
import { ProductInfo } from "../constant/product.constant";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@naf/table';
import { ProductService } from "../services/ProductService";
import { Button } from "@naf/button";
import { Toggle } from "@naf/toggle";
import { Input, Label } from "@naf/input";
import { OrderService } from "../services/OrderService";
import { useToast, ToastVariant } from "@naf/toast";
import { User } from "../context/userContext";
import { CustomerService } from "../services/CustomerService";
import { RadioButton } from "@naf/radio-button";
import { Tooltip } from "@naf/parts";


const Style = styled.div`
  .order-details-container {
    background: #fffaec;
    border-radius: 4px;
    padding: 32px;
  }
  .memebership-type-container {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .total {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    border-top: 1px solid #e5e5e5;
  }

  .w-4em {
    width: 43em;
  }
  .w-13em {
    width: 13em;
  }
  .grid-gap-25 {
    grid-gap: 25%;
	}
`;
export default function Invoice(props) {
  const [isIDLProduct, setIDLProduct] = useState(false);
  const [products, setProducts] = useState([]);

  const [selctedProducts, setSelctedProducts] = useState([]);

  const [Total, setTotal] = useState(0);
  const [markInvoicePaid, setMarkInvoicePaid] = useState(true);
  const [comment, setcomment] = useState(null);
  const { customer, invoice } = props;
  const [orderCompleted, setOrderCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addMoreLoading, setaddMoreLoading] = useState(false);
  const [publicationFormat, setPublicationFormat] = useState("digital");
  const [ispublicationFormat, setispublicationFormat] = useState(false);
  const [membershipProductId, setmembershipProductId] = useState(null);

  const { showToast, Toast } = useToast();
  const [user] = User();

const serviceProducts = ["8002", "8003"];

  const processProduct = async () => {
    const customerService = new CustomerService();
    const membershipDetail = await customerService.getMembershipByCustomerId(props.customer.customerId);    
    const isActiveMembership =membershipDetail?.membership?.status === 1 || membershipDetail?.membership?.status === 4;
    const productService = new ProductService();
    productService.getProductListByCustomerId(props.customer.customerId, props.membership.numberOfPersonsInHousehold,isActiveMembership).then(res => {
      const orderProductIds = ["1111", "1121", "1131", "1601", "8002", "8003"]
      const orderProducts = res?.products?.length ? res.products.sort((a, b) => a.productNumber - b.productNumber) : []
      const mainProducts = ["1111", "1121", "1131"]     
      
      orderProducts.forEach(prod => {
        if ((membershipDetail?.membership?.status === 1 || membershipDetail?.membership?.status === 4 || membershipDetail?.membership?.hasUnpaidInvoice) 
              && mainProducts.includes(prod.productNumber) && !serviceProducts.includes(prod.productNumber)) {
          prod.disabled = true;
        }
        else if(serviceProducts.includes(prod.productNumber)){
          prod.disabled = false;
        }
        else {
          prod.disabled = false;
        }
        prod.selected = false;
      })
     
      // Default value for product2020.disabled is true
let product2020Disabled = true;
setmembershipProductId(membershipDetail?.membership?.product?.id);
// Update product2020.disabled based on membership product ID
if (membershipDetail?.membership?.product?.id === "1121" || membershipDetail?.membership?.product?.id === "1131") {
    product2020Disabled = false;
}
// Find and update product2020.disabled in orderProducts array
const product2020 = orderProducts?.find(product => product?.productNumber === "2020");
if (product2020) {
    product2020.disabled = product2020Disabled;
}
      setProducts(orderProducts.filter(prod => prod.isVisible === true))
      setSelctedProducts([])
      setOrderCompleted(false)
      setLoading(false)
      setMarkInvoicePaid(true)

    }).catch(err => {
      console.log("error")
    })
  }

  useEffect(() => {
    if (!props?.customer?.customerId) {
      setSelctedProducts([])
      setOrderCompleted(false)
      setLoading(false)
      setMarkInvoicePaid(true);
      return;
    }
    processProduct()

  }, [props.customer.customerId])






  const calculateTotal = () => {
    let sum = 0
    selctedProducts.forEach(prod => {
      sum = sum + prod.netPrice;
    })
    setTotal(sum)
  }

  useEffect(() => {
    calculateTotal()
  }, [products])

  const handleChange = (event) => {
    setPublicationFormat(event.target.value);
  };

  const addProduct = (product) => {
    const singleProducts = ["1111", "1121", "1605", "1131"];
    const multipleProducts = ["1601", "8002", "8003"];
    const iDLProducts = [ "8002", "8003"];
    if (singleProducts.includes(product.productNumber)) {
      products.forEach(prod => {
        if (singleProducts.includes(prod?.productNumber) && prod?.productNumber !== product?.productNumber) {
          prod.disabled = true;
        }
      })
    }
  
   if (product?.productNumber === "1111") {
    const product2020 = products?.find(product => product?.productNumber === "2020");
    if (product2020) 
    {
     product2020.disabled = true;          
    }
    // Remove the product with ID "2020" from selectedProducts
    const updatedSelectedProducts = selctedProducts.filter(prod => prod?.productNumber !== "2020");
    if(updatedSelectedProducts.length > 0)
    {
      setSelctedProducts(updatedSelectedProducts);  
    }   
    else
    {
      setSelctedProducts([]); 
    } 

  }   

    product.selected = true;
   //setSelctedProducts([...selctedProducts, product])
   setSelctedProducts(prevProducts => [...prevProducts, product]);
    setProducts([...products])
    hideShowCommentBox([...selctedProducts, product]);
    hideShowpublicationFormatBox ([...selctedProducts, product]);   
  }

  const hideShowCommentBox = (products) =>{
    const productNumbers = products.map(item => item.productNumber );
    if (productNumbers.includes("8002") || productNumbers.includes("8003") ) {
      setIDLProduct(true)
    }
    else{
      setIDLProduct(false)

    }
  }

  const hideShowpublicationFormatBox = (products) =>{
    const productNumbers = products.map(item => item.productNumber );
    if (productNumbers.includes("1111") || productNumbers.includes("1121") ) {
      setispublicationFormat(true)
    }
    else{
      setispublicationFormat(false)
    }
  }

  const removeProduct = (product) => {
    const singleProducts = ["1111", "1121", "1605", "1131"];
    const multipleProducts = ["1601", "8002", "8003"];
    if (singleProducts.includes(product.productNumber)) {
      products.forEach(prod => {
        if (singleProducts.includes(prod.productNumber)) {
          prod.disabled = false;
        }
      })
    }

    if (product?.productNumber === "1111")
    {   
          const product2020 = products?.find(product => product?.productNumber === "2020");
         if (product2020 && (membershipProductId==="1121" || membershipProductId==="1131"))
         {
          product2020.disabled = false;          
         }
    }
    product.selected = false;

    setSelctedProducts(selctedProducts.filter(prod => prod.productNumber !== product.productNumber))
    setProducts([...products])
    hideShowCommentBox(selctedProducts.filter(prod => prod.productNumber !== product.productNumber));
    hideShowpublicationFormatBox(selctedProducts.filter(prod => prod.productNumber !== product.productNumber));
  }

  const order = () => {
    setLoading(true)
    const orderService = new OrderService();
    const payload = {
      "CustomerId": props.customer.customerId,
      "CustomerNumber": props.customer.customerNumber,
      "MarkInvoiceAsPaid": markInvoicePaid,
      "OfficeLocationNumber": localStorage.getItem("officeLocationNumber"),
      "NumberOfPersonsInHousehold":props.membership.numberOfPersonsInHousehold,
      "SalesOrder": {
        "Status": "submitted",
        "SalesAgent": localStorage.getItem("email"),
        "SalesLocationNumber": localStorage.getItem("officeLocationNumber"),
        "SalesChannel": "web",
        "PaymentInterval": "yearly",
        "Comment": comment,
        publicationFormat: publicationFormat,
        "SalesOrderLines": selctedProducts.map(prod => {
          return {
            "ProductNumber": prod.productNumber,
            "ListPrice": prod.listPrice,
            "BestPrice": prod.bestPrice,
            "VolumeDiscount": prod.volumeDiscount,
            "TimeUntilRenewalReduction": prod.timeUntilRenewalReduction,
            "TotalDiscount": prod.totalDiscount,
            "NetPrice": prod.netPrice,
            "ProductUserCustomerId": props.customer.customerId
          }
        })
      }
    }

    orderService.createSalesOrder(payload).then(res => {
      setLoading(false);
      setOrderCompleted(true);
      setcomment('');
    }).catch(() => {
      setLoading(true);
    })
  }

  const addMoreProducts = () => {
    setaddMoreLoading(true)
    setTimeout(() => {
      setaddMoreLoading(false)
      setOrderCompleted(false);
      setProducts([]);
      processProduct();

    }, 5000);
  }


  return (
    <Style>
      <Toast></Toast>


      <Grid>
        <GridRow  className="grid-gap-25">
          <GridCol m="7" l="7" xl="7" className="pt-0 pb-0">
            {orderCompleted && <>

              <>
                <div style={{ marginBottom: "40px" }}>
                  <Text style={{ margin: "0px" }} tag="h2" variant="header2">
                  Bestillingen er utført!
                  </Text>
                </div>
                <div style={{ paddingBottom: "14px" }}>
                  <Text style={{ margin: "0px" }} tag="h3" variant="header3">
                    Medlemsnummer:{" "}
                    {/* {invoice?.invoiceOrderNumber || invoice?.invoiceId} */}
                    {customer?.customerNumber}
                  </Text>
                </div>
                <div>
                  <div>


                    <Text style={{ margin: "0px" }} variant="body">
                    Det kan ta opptil 5 minutter før produktet(ene) er synlige på kunden i CRM portalen.
                    </Text>
                  </div>
                  <div>


                    <Text style={{ margin: "0px" }} variant="body">
                    Bekreftelse vil bli sendt til {customer?.basic?.email}.
                    </Text>
                  </div>
                  <div>

                    <Text style={{ margin: "0px", marginTop: "1em" }} variant="body">
                      Husk å registrere betaling i kassen.
                    </Text>
                  </div>
                </div>

              </>


            </>}

            {
              !orderCompleted && <>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className="w-13em" as="th">Produkt</TableCell>
                      <TableCell align="center" as="th">Pris</TableCell>
                      <TableCell align="center" as="th"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map((v, index) => <TableRow key={index}>
                      <TableCell align="center">{v.productName}</TableCell>
                      <TableCell align="center">Kr {v.netPrice},-</TableCell>
                      <TableCell align="center">
                        {v.selected ? <Button onClick={() => removeProduct(v)}                         
                          size="small"                          
                          disabled={v.disabled}
                        >Fjern</Button> : <Button onClick={() => addProduct(v)}                          
                          size="small"                         
                          disabled={v.disabled}
                        >Legg til</Button>}
                      </TableCell>
                    </TableRow>)}
                  </TableBody>
                </Table>
              </>
            }
          </GridCol>
          <GridCol m="5" l="5" xl="5">
            {selctedProducts?.length > 0 && <>       <div className="order-details-container">



              {selctedProducts.map(product => {
                return <>
                  <div className="memebership-type-container">

                    <Text
                      style={{ margin: "0px", marginRight: "38px" }}
                      variant="bodyHeader"
                    >
                      {product.productName}
                    </Text>
                    <Text
                      style={{ margin: "0px", whiteSpace: "nowrap" }}
                      variant="bodyHeader"
                    >
                      {product.netPrice},-
                    </Text>
                  </div>
                </>
              })}
              <div className="total">
                <Text style={{ margin: "0px" }} variant="bodyHeader">
                  Beløp som skal betales
                </Text>
                <Text style={{ margin: "0px" }} variant="subHeader">
                  {Total},-
                </Text>
              </div>
             {isIDLProduct && <div>
              <Text style={{ margin: "0px" }} variant="bodyHeader">
              Oppgi fødested og førerkortnummer:
                </Text>
              <Input width={"100%"} placeholder={"Oppgi fødested og førerkortnummeret på det internasjonale førerkortet."} as={"textarea"}
                             onChange={(val) => setcomment(val)}
                            value={comment}
                            name="comment"/>
              </div>
            }

{ispublicationFormat &&
                    <GridCol m="12" l="12" xl="12">
                    <div
                      className="member-info border"
                      style={{ padding: "0.6em" }}
                    >
                      <Label
                        style={{ margin: "0px", marginBottom: "15px" }}
                        tag="h3"
                        variant="header3"
                      >
                        Tilleggsvalg{" "}
                      </Label>
                      <div
                        className="member-info border"
                        style={{ padding: "0.6em" }}
                      >
                        <Label
                          style={{
                            margin: "0px",
                            marginTop: 0,
                            fontWeight: "bold",
                          }}
                          tag="h4"
                          variant="header4"
                        >
                          NAF Veibok{" "}
                          <Tooltip text="Dette er en kort og informativ hjelpetekst" />
                        </Label>

                        <div
                          className="d-flex justify-content-between"
                          style={{ marginTop: "15px" }}
                        >
                          <RadioButton
                            label="Ja, jeg vi ha veibok i posten"
                            value="paper"
                            onClick={handleChange}
                            checked={publicationFormat === "paper"}
                            name="publicationFormat"
                          />
                        </div>
                        <div
                          className="d-flex justify-content-between"
                          style={{ marginTop: "10px" }}
                        >
                          <RadioButton
                            label="Nei, jeg vil ikke ha veibok i posten."
                            value="digital"
                            onClick={handleChange}
                            checked={publicationFormat === "digital"}
                            name="publicationFormat"
                          />
                        </div>
                      </div>
                    </div>
                  </GridCol>
}


              {!orderCompleted && <div className=" justify-content-center d-flex p-1" >
                <Button                 
                  size="small"
                  isLoading={loading}
                  onClick={order}                
                >Bestill og betal</Button>

              </div>
              }



            </div> </>}
          </GridCol>
          <GridCol m="12" l="12" xl="12">
            {orderCompleted && <div className="d-flex justify-content-center m-1">

              <Button
                variant="primary"
                size="small"
                isLoading={addMoreLoading}
                onClick={() => { addMoreProducts() }}                
              >Bestill flere produkter</Button>
            </div>}
          </GridCol>
        </GridRow>
      </Grid>
    </Style >
  );
}
