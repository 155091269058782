import axios from "axios";
export class OrderService {
  service = "sales-order";

  createSalesOrder(saleOrder) {
    const url = `${process.env.REACT_APP_BASE_URL}/${this.service}/create`;

    return axios.post(url, saleOrder);
  }
  getProductListByCustomerId(customerId, numberOfPersonsInHousehold = 0) {
    const url = `${process.env.REACT_APP_BASE_URL}/${this.service}/productspriceforcrmportal`;
    return axios.get(url, {params: {customerId, numberOfPersonsInHousehold} });

  }
}
