import axios from "axios";
export class MasterService {
  service = "masterdata";

  getcityByPostCode(postalCode) {
    const url = `${process.env.REACT_APP_BASE_URL}/${this.service}/getcitybypostalcode`;

    return axios.get(url, {params: {postalCode} });
  }

  getSalesLocations() {
    const url = `${process.env.REACT_APP_BASE_URL}/${this.service}/getsaleslocations`;

    return axios.get(url);
  }
}
